<template>
    <div class="notificationWrapper">
        <div v-if="unreadNotes > 0" class="unreadNotes position-absolute top-0 start-0 translate-middle d-flex justify-content-center align-items-center">
            {{ unreadNotes }}
        </div>
        <div class="me-2 notificationButton">
            <AtomDropdown
                pill
                no-caret
                variant="link"
                toggle-class="text-decoration-none"
                style-variant="secondary"
                @click="setReadOnOpen()"
                id="NotificationToggle"
            >
                <template #button-content>
                    <AtomIcon name="bell-line"/>
                </template>
                <AtomDropdownItem :data-id="notification.id" class="NotificationItem" v-for="notification in notifications.notificationList" :key="notification.id">
                    <div class="row">
                        <div class="col-auto">
                            <hr :class="notification.isRead === true ? 'isRead' : ''" style="display: inline-block; height: 85%; margin-bottom: 0;">
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <strong>{{ $t(notification.title) }}</strong>
                                </div>
                                <div class="col-auto d-flex justify-content-end align-items-end">
                                    <span class="date">
                                        {{  $d(notification?.date, 'short') }}                                    
                                    </span>
                                </div>
                            </div>
                            <div v-if="notification.image" class="row">
                                <div class="col">
                                    <AtomImage v-if="$i18n.locale === 'de'" :src="notification.image.de" />
                                    <AtomImage v-else :src="notification.image.en" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <p>
                                        {{ $t(notification.message) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="notification.link !== ''" class="row">
                        <div class="col d-flex justify-content-center">
                            <AtomTextLink @click="setRead(notification)" :to="{name: notification.link}">
                                {{ $t('notifications.link') }}
                            </AtomTextLink>
                        </div>
                    </div>
                </AtomDropdownItem>
            </AtomDropdown>
        </div>
    </div>
</template>
<script setup>
    import { notificationStore } from '@/stores/notificationStore';
    import { computed, onMounted } from 'vue';

    const notifications = notificationStore();

    notifications.updateNotifications();

    const unreadNotes = computed(() => {
        let count = 0;
        notifications.notificationList.forEach( item => {
            if (item.isRead === false) {
                count = count + 1;
            }
        });

        return count > 9 ? '9+' : count;

    });

    function notificationInViewport(element) {
        let rect = element.getBoundingClientRect();
        let html = document.documentElement;
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || html.clientHeight) &&
            rect.right <= (window.innerWidth || html.clientWidth)
        );
    }


    onMounted(() => {
        const notificationContainer = document.querySelector('#NotificationToggle ul');
        notificationContainer.addEventListener('scroll', function () {
            setMultipleRead();
        });
    });

    function setMultipleRead() {
        const notificationsItems = document.getElementsByClassName('NotificationItem');
        for (let index = 0; index < notificationsItems.length; index++) {
            if (notificationInViewport(notificationsItems[index]) === true) {
                notifications.notificationList.forEach( item => {

                    if (item.id === parseInt(notificationsItems[index].firstChild.dataset.id)) {
                        if (item.link === '') {
                            item.isRead = true;
                        }
                    }

                });
            }
        }
    }

    function setReadOnOpen() {
        setTimeout(() => {
            setMultipleRead();
        }, 2000);
    }


    function setRead(notification) {
        notification.isRead = true;
    }


</script>
<style lang="scss">
.notificationButton {
    border-radius: 20% !important;
    background-color: $app-bg;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0 .5rem;
    height: auto !important;

    img {
        width: 100% !important;
    }

    .date {
        font-size: .9rem;
    }

    p {
        width: 100% !important;
        height: 100% !important;
        white-space: initial;
        margin-bottom: 0;
    }

    hr {
        border-left: 3px solid $primary;
        z-index: 20;
        width: 1px;
        position: relative;
        border-end-end-radius: 100% !important;
    }

    .isRead {
        border-left: 3px solid $app-bg;
    }

    .atom-text-link {
        cursor: pointer !important;
    }

    .atom-dropdown .dropdown-item {
        border-bottom: 1px solid $app-bg;
        cursor: default;
        padding-right: 22px;
    }

    .atom-dropdown .dropdown-item:hover {
        background: $white;
        color: $secondary;
    }

    .atom-dropdown .btn-group.secondary > button {
        background: $app-bg !important;
        padding: 0;

        span.atom-icon {
            margin-right: 0;
            padding: 0 .5rem;
            font-size: 1.5rem;
            margin-top: -3px;
        }
    }

    .atom-dropdown ul.dropdown-menu {
        max-width: 500px !important;
        height: 60vh;
        max-height: unset !important;
        width: 25vw;
        min-width: 400px;
    }

}

.unreadNotes {
    border-radius: 100% !important;
    font-size: 1rem;
    color: $white;
    background-color: $primary;
    width: 26px;
    height: 26px;
    z-index: 10;
}

.notificationWrapper {
    position: relative;
}
</style>