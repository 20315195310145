import { userStore } from '@development/cluster-center';

export default function auth({ nextRoute }) {
    const store = userStore();

    if (!store.isLoggedIn) {
        store.refreshToken();
        return nextRoute({ name: 'login' });
    }

    return nextRoute();
}
