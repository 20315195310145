import { ApiService } from "@development/cluster-center";
import accounting from "@/apiRoutes/accounting";

export default class PartnerService extends ApiService
{
    static async getMine()
    {
        return await this.get(accounting.partners_mine);
    }

    static async getSalesMaterial()
    {
        return await this.get(accounting.partners_sales_material);
    }

    static async getLevels()
    {
        return await this.get(accounting.partners_levels);
    }

    static async getProvisions(params, partnerId)
    {
        return await this.get(accounting.partners_provisions, params, {partnerId: partnerId});
    }

    static async getExpectedRevenue(partnerId)
    {
        return await this.get(accounting.partners_expected_revenue, {}, {partnerId: partnerId});
    }

    static async downloadSalesMaterial(params)
    {
        return await this.pdf(accounting.partners_sales_material_download, {}, params);
    }

    static async downloadPartnerContract(params)
    {
        return await this.pdf(accounting.partners_partner_contract, {}, params);
    }
}