<template>
    <div v-if="inMenu===false" class="row">
        <div class="col-12 mb-3">
            <h4>{{ $t('profile.personal_data.ui') }}</h4>
        </div>
        <div class="col-6 d-flex justify-content-start">
            <AtomCheckbox
                id="mode-toggle"
                v-model="selected"
                :options="options"
                name="modeChange"
                switches
                @click="setForced"
            />
            <AtomCheckbox
                v-model="fullwidth"
                :options="fullWidthOptions"
                switches
            />
        </div>
    </div>
    <div v-else class="row">
        <hr class="mt-0">
        <div class="mb-1">
            <p>{{ $t('profile.personal_data.ui') }}</p>
        </div>
        <div class="col d-flex justify-content-start">
            <AtomCheckbox
                id="mode-toggle"
                v-model="selected"
                :options="options"
                name="modeChange"
                switches
                @click="setForced"
            />
        </div>
        <div class="col d-flex justify-content-start">
            <AtomCheckbox
                v-model="fullwidth"
                :options="fullWidthOptions"
                switches
            />
        </div>
        <hr class="mb-0">
    </div>
</template>
<script setup>
    import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
    import { useColorMode } from 'bootstrap-vue-next';
    import { userStore } from "@development/cluster-center";
    import { share } from 'pinia-shared-state';

    defineProps({
        inMenu: {
            type: Boolean,
            default: false
        }
    });

    const i18n = window.Config.get('i18n');
    const store = userStore();
    const target = document.querySelector('html');
    const mode = useColorMode({
        selector: target,
    });

    const selected = ref([]);
    const options = ref([
        {text: 'Dark Mode', value: true}
    ]);

    function setForced () {
        store.forcedMode = true;

    }

    onMounted(() => {
        selected.value = store.darkMode === true ? [true] : [];

        const { unshare: containerUnshare } = share('container', store, { initialize: true });
        const { unshare: darkModeUnshare } = share('darkMode', store, { initialize: true });

        onUnmounted(() => {
            try {
                containerUnshare();
                darkModeUnshare();
            } catch {}
        });
    });

    const changeBySync = ref(false);

    watch(() => selected.value, val => {
        if (!changeBySync.value) {
            store.darkMode = val && val.length > 0;
            mode.value = val && val.length > 0 ? 'dark' : '';
        }
    });

    const fullWidthOptions = computed(() => {
        return [
            { text: i18n.t('profile.personal_data.reduced_width'), value: 'wrapped'}
        ];
    });

    const fullwidth = ref(store.container === 'wrapped' ? ['wrapped'] : []);

    watch(() => fullwidth.value, () => {
        if (!changeBySync.value) {
            store.toggleContainer();
        }
    });

    store.$subscribe((mutation, state) => {
        changeBySync.value = true;

        fullwidth.value = state.container === 'wrapped' ? ['wrapped'] : [];
        selected.value = state.darkMode === true ? [true] : [];

        setTimeout(() => {
            changeBySync.value = false;
        }, 250);
    });
</script>
