import { userStore } from '@development/cluster-center';

export default function isPartnerManager({ nextRoute }) {
    const store = userStore();

    if (!store.me.is_partner) {
        return nextRoute({ name: 'error-404' });
    }

    return nextRoute();
}
