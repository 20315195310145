import { iubendaConfiguration } from '@/config/iubendaConfiguration';
import {userStore} from "@development/cluster-center";

export function initIubendaCookieBanner() {
    const user = userStore();
    window._iub = window?._iub || [];
    window._iub.csConfiguration = iubendaConfiguration;

    window._iub.csConfiguration.lang = user.language;

    const scripts = [
        {src: `//cs.iubenda.com/sync/${iubendaConfiguration.siteId}.js`},
        {src: '//cdn.iubenda.com/cs/tcf/stub-v2.js'},
        {src: '//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js'},
        {src: '//cdn.iubenda.com/cs/iubenda_cs.js', charset: 'UTF-8', async: true}
    ];

    scripts.forEach(script => {
        let scriptElement = document.createElement('script');

        for (let attr in script) {
            scriptElement.setAttribute(attr, script[attr]);
        }

        scriptElement.setAttribute('type', 'text/javascript');
        document.body.appendChild(scriptElement);
    });
}