export default {
    de: {
        title: 'Rechnungen',
        show_details: 'Details ansehen',
        public_links: 'Öffentliche Links',
        pdf: 'PDF',
        renew_link: 'Link erneuern',
        create_link: 'Link erzeugen',
        active_link: 'Aktiver Link',
        link_expired_at: 'Dieser Link läuft am {date} ab.',
        expired_links: 'Abgelaufene Links',
        table: {
            link: 'Link',
            expired_at: 'Abgelaufen am',
        },
        copied_link: 'Der Link wurde in die Zwischenablage kopiert',
        no_active_link: 'Aktuell existiert kein aktiver Link. Sie können einen Link erzeugen, in dem Sie auf den Button <strong>Link erzeugen</strong> klicken.',
        not_found: {
            title: 'Rechnung nicht gefunden',
            description: 'Die angeforderte Rechnung konnte nicht gefunden werden. Bitte überprüfen Sie die Rechnungsnummer und versuchen Sie es erneut.',
        },
        error: {
            title: 'Fehler beim laden',
            description: 'Die angeforderte Rechnung konnte nicht geladen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder kontaktieren Sie unseren Service (<a href="mailto:{email}">{email}</a> oder <a href="tel:{phone}">{phone}</a>).',
        }
    },
    en: {
        title: 'Invoices',
        show_details: 'Show details',
        public_links: 'Public links',
        pdf: 'PDF',
        renew_link: 'Renew link',
        active_link: 'Active link',
        create_link: 'Create link',
        link_expired_at: 'This link will expire at {date}.',
        expired_links: 'Expired links',
        table: {
            link: 'Link',
            expired_at: 'Expired at',
        },
        copied_link: 'The link was copied to the clipboard.',
        no_active_link: 'Currently there is no active link. You can create a link by clicking on the button <strong>Create link</strong>.',
        not_found: {
            title: 'Invoice not found',
            description: 'The requested invoice could not be found. Please check the invoice number and try again.',
        },
        error: {
            title: 'Error loading',
            description: 'The requested invoice could not be loaded. Please try again later or contact our service (<a href="mailto:{email}">{email}</a> or <a href="tel:{phone}">{phone}</a>).',
        }
    }
};
