<template>
    <PartnerHeaderLine />
    <div class="row" v-bind="$attrs">
        <div class="col-12 mt-2 mb-2">
            <h4>{{ $t('partner.sales_material.title') }}</h4>
        </div>
        <MoleculeLoader size="medium" v-if="actionIsRunning" />
        <p v-else-if="salesMaterial.length === 0">{{ $t('partner.no_sales_material') }}</p>
        <template v-else>
            <div class="col-12 mb-3 d-flex">
                <AtomFilter :filters="filters" single @update:filters="activeFilter = $event[0]"/>
            </div>
            <div class="col-12 sales-material">
                <OrganismAutoSortableTable
                    :data="filteredMaterial"
                    :configuration="tableConfiguration"
                    initial-sorting-key="date"
                    initial-sorting-direction="desc"
                    pagination
                    :id="[`${userId}-partner-sales-material-table`]"
                    configurable
                    :configure-reset-button-text="$t('helper.actions.reset')"
                    :configure-title-text="$t('helper.labels.hide_and_show_columns')"
                    :configure-tooltip-text="configureTooltipText"
                    @updated:config="hiddenColumns = $event.hidden"
                >
                    <template #column-type="material">{{ material.type.toUpperCase() }}</template>
                    <template #column-lang="material">{{ material.lang.toUpperCase() }}</template>
                    <template #column-date="material">{{ $d(material.date, 'short') }}</template>
                    <template #column-menu="material">
                        <AtomButton variant="icon" @click.prevent="downloadSalesMaterial(material)">
                            <AtomIcon name="download-line" />
                        </AtomButton>
                    </template>
                </OrganismAutoSortableTable>
            </div>
        </template>
    </div>
</template>
<script>
    import PartnerService from "@/services/Api/Accounting/PartnerService";
    import {Logger, ResponseService, userStore} from "@development/cluster-center";
    import PartnerHeaderLine from "@/components/Accounting/Partner/PartnerHeaderLine.vue";
    import BaseComponent from "@/components/BaseComponent.vue";

    export default {
        extends: BaseComponent,
        name: 'PartnerSalesMaterial',
        components: {PartnerHeaderLine},
        data() {
            return {
                salesMaterial: [],
                filters: [{
                    name: this.$i18n.t('partner.sales_material.filter.all'),
                    active: true
                }, {
                    name: this.$i18n.t('partner.sales_material.filter.de')
                }, {
                    name: this.$i18n.t('partner.sales_material.filter.en')
                }],
                activeFilter: null,
                hiddenColumns: [],
                user: null
            };
        },
        mounted() {
            this.user = userStore();
            this.getSalesMaterial();
        },
        computed: {
            userId() {
                return this.user.id;
            },
            configureTooltipText() {
                return window.Config.get('i18n').tc('helper.labels.hide_columns', this.hiddenColumns.length);
            },
            tableConfiguration() {
                return [
                    {key: 'displayName', title: this.$i18n.t('partner.material_table.name'), sortable: true},
                    {key: 'type', title: this.$i18n.t('partner.material_table.file_type')},
                    {key: 'lang', title: this.$i18n.t('partner.material_table.language'), sortable: true},
                    {key: 'size', title: this.$i18n.t('partner.material_table.size')},
                    {key: 'date', title: this.$i18n.t('partner.material_table.publication_date'), sortable: true},
                    {key: 'menu', title: '', width: 55},
                ];
            },
            filteredMaterial() {
                if (
                    !this.activeFilter ||
                    this.activeFilter === this.$i18n.t('partner.sales_material.filter.all')
                ) {
                    return this.salesMaterial;
                }

                let filter = 'de';

                if (this.activeFilter === this.$i18n.t('partner.sales_material.filter.en')) {
                    filter = 'en';
                }

                return this.salesMaterial.filter(item => item.lang.toLowerCase() === filter.toLowerCase());
            }
        },
        methods: {
            async getSalesMaterial() {
                const i18n = window.Config.get('i18n');
                this.actionIsRunning = true;

                await PartnerService.getSalesMaterial().then(response => {
                    if (Array.isArray(response)) {
                        if (response[2] === 401) {
                            return;
                        }

                        return ResponseService.danger(i18n.t(response[1], {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }));
                    }

                    this.salesMaterial = response.data?.material || [];
                }).catch(e => {
                    Logger.log(e);
                }).finally(() => {
                    this.actionIsRunning = false;
                });
            },
            async downloadSalesMaterial(material) {
                const i18n = window.Config.get('i18n');

                return await PartnerService.downloadSalesMaterial({lang: material.lang, basename: material.basename}).then(response => {
                    if (Array.isArray(response)) {
                        if (response[2] === 401) {
                            return;
                        }

                        return ResponseService.danger(i18n.t(response[1], {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }));
                    }

                    window.downloadPdf(response.data, material.basename);
                });
            },
        }
    };
</script>
<style>
    .sales-material td .btn {
        height: 2rem;
        width: 2rem;
    }
</style>