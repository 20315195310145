export default {
    de: {
        commission_credit: 'Provisionsgutschrift',
        creation_date: 'Erstelldatum',
        payout_date: 'Auszahlungsdatum',
        intended_purpose: 'Verwendungszweck',
        amount: 'Betrag',
        status: 'Status',
        overview: 'Übersicht',
        partner_contract: 'Partnervereinbarung',
        contracts: 'Verträge',
        levels: {
            bronze: 'Bronze',
            silver: 'Silber',
            gold: 'Gold',
            platinum: 'Platin'
        },
        material_table: {
            name: 'Name',
            file_type: 'Dateityp',
            language: 'Sprache',
            size: 'Größe',
            publication_date: 'Stand'
        },
        navigation: {
            overview: 'Übersicht',
            payouts: 'Auszahlungen',
            sales_material: 'Vertriebsmaterial',
            contracts: 'Verträge',
        },
        commission_levels: {
            title: 'Provisionsstufen',
            area: 'Bereich',
            performance: 'Leistung',
            bronze: 'Bronze (ab {amount})',
            silver: 'Silber (ab {amount})',
            gold: 'Gold (ab {amount})',
            platinum: 'Platin (ab {amount})',
            commission: 'Provision',
            support: 'Support',
            agency_cluster: 'Agentur Cluster im Wert von {amount}',
            partner_manager_agency: 'Partnermanager für Agentur',
            sales_material: 'Vertriebsunterstützendes Material',
            advice: 'Beratung',
            accompaniment_to_pitch: 'Begleitung zum Pitch / Video',
            marketing: 'Marketing',
            logo_mention: 'Logo Nennung',
            customer_citation: 'Kundenzitat / Slider',
            testimonial: 'Testimonial',
            case_study: 'Case Study / Partnerporträt',
            marketing_newsletter: 'Vorstellung Marketing Newsletter',
            video: 'Vorstellung im Video',
            others: 'Sonstiges',
            dedicated_project_manager: 'Dedizierter Projektmanager',
        },
        partner_manager: 'Partnermanager',
        newest_contracts: {
            title: 'Neueste Verträge',
            description: 'Hier finden Sie die Übersicht Ihrer betreuten Verträge nach Aktualität sortiert.',
            show_all: 'Alle Verträge anzeigen',
            status: {
                active: 'Aktiv',
                ended: 'Beendet'
            },
            table: {
                contract_number: 'Vertrag',
                customer: 'Kunde',
                from: 'Von',
                to: 'Bis',
                last_relevant_revenue: 'Umsatz',
                last_relevant_revenue_tooltip: 'Letzter relevanter Umsatz',
                last_relevant_commission: 'Provision',
                last_relevant_commission_tooltip: 'Letzte relevante Provision',
                status: 'Status',
            }
        },
        no_payouts: 'Es existieren noch keine Auszahlungen.',
        no_sales_material: 'Es existiert noch kein Vertriebsmaterial.',
        no_contracts: 'Keine Verträge vorhanden.'
    },
    en: {
        commission_credit: 'Commission credit',
        creation_date: 'Creation date',
        payout_date: 'Payout date',
        intended_purpose: 'Intended purpose',
        amount: 'Amount',
        status: 'Status',
        overview: 'Overview',
        partner_contract: 'Partner contract',
        contracts: 'Contracts',
        levels: {
            bronze: 'Bronze',
            silver: 'Silver',
            gold: 'Gold',
            platinum: 'Platinum'
        },
        material_table: {
            name: 'Name',
            file_type: 'File type',
            language: 'Language',
            size: 'Size',
            publication_date: 'Publication date'
        },
        navigation: {
            overview: 'Overview',
            payouts: 'Payouts',
            sales_material: 'Sales material',
            contracts: 'Contracts',
        },
        commission_levels: {
            title: 'Commission levels',
            area: 'Area',
            performance: 'Performance',
            bronze: 'Bronze (from {amount})',
            silver: 'Silver (from {amount})',
            gold: 'Gold (from {amount})',
            platinum: 'Platinum (from {amount})',
            commission: 'Commission',
            support: 'Support',
            agency_cluster: 'Agency cluster worth {amount}',
            partner_manager_agency: 'Partner manager for agency',
            sales_material: 'Sales-supporting material',
            advice: 'Consulting',
            accompaniment_to_pitch: 'Accompaniment to pitch / video',
            marketing: 'Marketing',
            logo_mention: 'Logo mention',
            customer_citation: 'Customer citation / slider',
            testimonial: 'Testimonial',
            case_study: 'Case study / partner portrait',
            marketing_newsletter: 'Presentation marketing newsletter',
            video: 'Presentation in video',
            others: 'Others',
            dedicated_project_manager: 'Dedicated project manager',
        },
        partner_manager: 'Partnermanager',
        newest_contracts: {
            title: 'Newest contracts',
            description: 'Here you can find an overview of your managed contracts sorted by date.',
            show_all: 'Show all contracts',
            status: {
                active: 'Active',
                ended: 'Ended'
            },
            table: {
                contract_number: 'Contract',
                customer: 'Customer',
                from: 'From',
                to: 'To',
                last_relevant_revenue: 'Revenue',
                last_relevant_revenue_tooltip: 'Last relevant revenue',
                last_relevant_commission: 'Commission',
                last_relevant_commission_tooltip: 'Last relevant commission',
                status: 'Status',
            }
        },
        no_payouts: 'There are no payouts yet.',
        no_sales_material: 'There is no sales material yet.',
        no_contracts: 'No contracts available.'
    }
};
