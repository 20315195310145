import ManagedDashboard from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ManagedDashboard";
import ManagedApache from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Apache/ManagedApache";
import ManagedNginx from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Nginx/ManagedNginx";
import ManagedCronjobs from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Cronjobs/ManagedCronjobs";
import ManagedProfiler from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Profiler/ManagedProfiler";
import ManagedSsh from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Ssh/ManagedSsh";
import ManagedSupervisor from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Supervisor/ManagedSupervisor";
import ManagedTomcat from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Tomcat/ManagedTomcat";
import ManagedVarnishSsl from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/VarnishSSL/ManagedVarnishSsl";
import ManagedMemcached from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/CacheAndQueue/Memcached/ManagedMemcached";
import ManagedRabbitMq from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/CacheAndQueue/RabbitMq/ManagedRabbitMq";
import ManagedRedis from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/CacheAndQueue/Redis/ManagedRedis";
import ManagedElasticsearch from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/Elasticsearch/ManagedElasticsearch";
import ManagedMysql from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/MySQL/ManagedMysql";
import ManagedOpenSearch from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/OpenSearch/ManagedOpenSearch";
import ManagedSolr from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/Solr/ManagedSolr";
import ManagedSphinx from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/Sphinx/ManagedSphinx";
import ManagedBackups from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/Backups/ManagedBackups";
import ManagedFirewall from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/Firewall/ManagedFirewall";
import ManagedProtection from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/Protection/ManagedProtection";
import ManagedShopSecurity from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/ShopSecurity/ManagedShopSecurity.vue";
import ManagedShopSecurityReport from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/ShopSecurity/ShopSecurityReport.vue";
import ManagedClusterControl from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ManagedClusterControl";
import ManagedDns from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Dns/ManagedDns";
import ManagedDnsShowZone from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Dns/ManagedDnsShowZone";
import ManagedEmail from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/EMail/MailServer/ManagedEmail.vue";
import ManagedEmailDomainDetails from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/EMail/MailServer/ManagedEmailDomainDetails.vue";
import ManagedPostfix from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/EMail/Postfix/ManagedPostfix.vue";
import ManagedFtp from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Ftp/ManagedFtp";
import ManagedNodejs from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Nodejs/ManagedNodejs";
import ManagedPackageUpdates from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ManagedPackageUpdates";
import ManagedShopPerformance from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ShopPerformance/ManagedShopPerformance";
import ManagedShopPerformanceReport from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ShopPerformance/ShopPerformanceReport";
import ManagedMonitoring from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Monitoring/ManagedMonitoring";

export default [{
        path: "/clusters/:clusterId/managed",
        name: 'managed-cluster',
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Dashboard'
        },
        props: {
            activeComponent: ManagedDashboard
        }
    }, {
    path: "/clusters/:clusterId/servers/:serverId/managed",
    name: 'managed-root',
    children: [{
        path: '',
        name: "managed-dashboard",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Dashboard'
        },
        props: {
            activeComponent: ManagedDashboard
        }
    }, {
        path: 'apache',
        name: "managed-apache",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Apache'
        },
        props: {
            activeComponent: ManagedApache
        },
        children: [{
            path: 'domains/create',
            name: "managed-apache-new-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Apache - New Domain'
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'domains/:hostname',
            name: "managed-apache-edit-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Apache - Edit'
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'logs/access',
            name: "managed-apache-logs-access",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Apache - Access Log'
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'logs/access/:host',
            name: "managed-apache-logs-access-selected",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Apache - Access Log'
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'logs/error',
            name: "managed-apache-logs-error",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Apache - Error Log'
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'logs/error/:host',
            name: "managed-apache-logs-error-selected",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Apache - Error Log'
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'redirects/create',
            name: "managed-apache-create-redirect",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Apache - Weiterleitung erstellen'
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'redirects/:hostname',
            name: "managed-apache-edit-redirect",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Apache - Weiterleitung bearbeiten'
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'authentications/create',
            name: "managed-apache-create-authentication",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Apache - Passwortschutz erstellen'
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'authentications/:hostname',
            name: "managed-apache-edit-authentication",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Apache - Passwortschutz bearbeiten'
            },
            props: {
                activeComponent: ManagedApache
            }
        }]
    }, {
        path: 'nginx',
        name: "managed-nginx",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - NGINX'
        },
        props: {
            activeComponent: ManagedNginx
        },
        children: [{
            path: 'domains/create',
            name: "managed-nginx-new-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - NGINX - New Domain'
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'domains/:hostname',
            name: "managed-nginx-edit-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - NGINX - Edit'
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'logs/access',
            name: "managed-nginx-logs-access",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - NGINX - Access Log'
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'logs/access/:host',
            name: "managed-nginx-logs-access-selected",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - NGINX - Access Log'
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'logs/error',
            name: "managed-nginx-logs-error",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - NGINX - Error Log'
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'logs/error/:host',
            name: "managed-nginx-logs-error-selected",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - NGINX - Error Log'
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'redirects/create',
            name: "managed-nginx-create-redirect",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - NGINX - Weiterleitung erstellen'
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'redirects/:hostname',
            name: "managed-nginx-edit-redirect",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - NGINX - Weiterleitung bearbeiten'
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'config',
            name: "managed-nginx-config",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - NGINX - NGINX.conf'
            },
            props: {
                activeComponent: ManagedNginx
            }
        }]
    }, {
        path: 'cronjobs',
        name: "managed-cronjobs",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Cronjobs'
        },
        props: {
            activeComponent: ManagedCronjobs
        }
    }, {
        path: 'profiler',
        name: "managed-profiler",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Profiler'
        },
        props: {
            activeComponent: ManagedProfiler
        }
    }, {
        path: 'ssh',
        name: "managed-ssh",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - SSH'
        },
        props: {
            activeComponent: ManagedSsh
        },
        children: [{
            path: 'key/create',
            name: "managed-ssh-new-key",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - SSH - New Key'
            },
            props: {
                activeComponent: ManagedSsh
            }
        }]
    }, {
        path: 'supervisor',
        name: "managed-supervisor",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Supervisor'
        },
        props: {
            activeComponent: ManagedSupervisor
        },
        children: [{
            path: 'logs',
            name: "managed-supervisor-logs",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Supervisor - Logs'
            },
            props: {
                activeComponent: ManagedSupervisor
            }
        }]
    }, {
        path: 'tomcat',
        name: "managed-tomcat",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Tomcat'
        },
        props: {
            activeComponent: ManagedTomcat
        }
    }, {
        path: 'varnish-ssl',
        name: "managed-varnish-ssl",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Varnish SSL'
        },
        props: {
            activeComponent: ManagedVarnishSsl
        },
        children: [{
            path: 'config',
            name: "managed-varnish-ssl-config",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Varnish SSL - Konfiguration'
            },
            props: {
                activeComponent: ManagedVarnishSsl
            }
        }, {
            path: 'logs',
            name: "managed-varnish-ssl-logs",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Varnish SSL - Logs'
            },
            props: {
                activeComponent: ManagedVarnishSsl
            }
        }, {
            path: 'startparameter',
            name: "managed-varnish-ssl-startparameter",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - Varnish SSL - Startparameter'
            },
            props: {
                activeComponent: ManagedVarnishSsl
            }
        }]
    }, {
        path: 'memcached',
        name: "managed-memcached",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Memcached'
        },
        props: {
            activeComponent: ManagedMemcached
        }
    }, {
        path: 'rabbit-mq',
        name: "managed-rabbit-mq",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - RabbitMQ'
        },
        props: {
            activeComponent: ManagedRabbitMq
        }
    }, {
        path: 'redis',
        name: "managed-redis",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Redis'
        },
        props: {
            activeComponent: ManagedRedis
        }
    }, {
        path: 'elasticsearch',
        name: "managed-elasticsearch",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Elasticsearch'
        },
        props: {
            activeComponent: ManagedElasticsearch
        }
    }, {
        path: 'mysql',
        name: "managed-mysql",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - MySQL'
        },
        props: {
            activeComponent: ManagedMysql
        }
    }, {
        path: 'open-search',
        name: "managed-open-search",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - OpenSearch'
        },
        props: {
            activeComponent: ManagedOpenSearch
        }
    }, {
        path: 'solr',
        name: "managed-solr",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Solr'
        },
        props: {
            activeComponent: ManagedSolr
        }
    }, {
        path: 'sphinx',
        name: "managed-sphinx",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Sphinx'
        },
        props: {
            activeComponent: ManagedSphinx
        }
    }, {
        path: 'backups',
        name: "managed-backups",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Backups'
        },
        props: {
            activeComponent: ManagedBackups
        }
    }, {
        path: 'firewall',
        name: "managed-firewall",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Firewall'
        },
        props: {
            activeComponent: ManagedFirewall
        }
    }, {
        path: 'protection',
        name: "managed-protection",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Protection'
        },
        props: {
            activeComponent: ManagedProtection
        }
    }, {
        path: 'shop-security',
        name: "managed-shop-security",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
          middleware: ['authenticated'],
          title: 'ManagedCenter - ShopSecurity'
        },
        props: {
          activeComponent: ManagedShopSecurity
        },
        children: [{
            path: 'report/:reportId/',
            name: 'managed-shopsecurity-report',
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - ShopSecurity - Report'
            },
            props: {
                activeComponent: ManagedShopSecurityReport
            }
        }]
    }, {
        path: 'cluster-control',
        name: "managed-cluster-control",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Cluster Control'
        },
        props: {
            activeComponent: ManagedClusterControl
        }
    }, {
        path: 'dns',
        name: "managed-dns",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - DNS'
        },
        props: {
            activeComponent: ManagedDns
        },
        children: [{
            path: 'zones/:zoneId/modus/:mode',
            name: "managed-dns-zone",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - DNS - Zone view'
            },
            props: {
                activeComponent: ManagedDnsShowZone
            }
        }]
    }, {
        path: 'email',
        name: "managed-email",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - E-Mail'
        },
        props: {
            activeComponent: ManagedEmail
        },
        children: [{
            path: 'logs',
            name: "managed-email-logs",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - E-Mail - Logs'
            },
            props: {
                activeComponent: ManagedEmail
            }
        }, {
            path: 'domains/create',
            name: "managed-email-new-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - E-Mail - New Domain'
            },
            props: {
                activeComponent: ManagedEmail
            }
        }, {
            path: 'domains/:domainId/verification',
            name: "managed-email-verification-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - E-Mail - Domain Verifizierung'
            },
            props: {
                activeComponent: ManagedEmail
            }
        }, {
            path: 'domains/:domainId',
            name: "managed-email-edit-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - E-Mail - Domain Details'
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/mailboxes/create',
            name: "managed-email-edit-domain-mailboxes-create",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - E-Mail - Domain Details - New Mailboxes'
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/mailboxes/:mailboxId',
            name: "managed-email-edit-domain-mailboxes-edit",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - E-Mail - Domain Details - Edit Mailboxes'
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/aliases/create',
            name: "managed-email-edit-domain-aliases-create",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - E-Mail - Domain Details - New Aliases'
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/aliases/:aliasId',
            name: "managed-email-edit-domain-aliases-edit",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - E-Mail - Domain Details - Edit Aliases'
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/bcc/create',
            name: "managed-email-edit-domain-bcc-create",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - E-Mail - Domain Details - New BCC'
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/bcc/:bccId',
            name: "managed-email-edit-domain-bcc-edit",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: 'ManagedCenter - E-Mail - Domain Details - Edit BCC'
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }]
    }, {
        path: 'postfix',
        name: "managed-postfix",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Postfix / Sendmail'
        },
        props: {
            activeComponent: ManagedPostfix
        },
    }, {
        path: 'ftp',
        name: "managed-ftp",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - FTP'
        },
        props: {
            activeComponent: ManagedFtp
        }
    }, {
        path: 'nodejs',
        name: "managed-nodejs",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - NodeJS'
        },
        props: {
            activeComponent: ManagedNodejs
        }
    }, {
        path: 'package-updates',
        name: "managed-package-updates",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Package Updates'
        },
        props: {
            activeComponent: ManagedPackageUpdates
        }
    }, {
        path: 'shop-performance',
        name: "managed-shop-performance",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - ShopPerformance'
        },
        props: {
            activeComponent: ManagedShopPerformance
        },
        children: [{
            path: 'report/:reportId/',
            name: 'managed-shopperformance-report',
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
              middleware: ['authenticated'],
              title: 'ManagedCenter - ShopPerformance - Report'
            },
            props: {
              activeComponent: ManagedShopPerformanceReport
            }
        }]
    }, {
        path: 'monitoring',
        name: "managed-monitoring",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: 'ManagedCenter - Monitoring'
        },
        props: {
            activeComponent: ManagedMonitoring
        }
    }]
}];
