import PersonalData from "@/components/Accounting/Profile/PersonalData";
import SecurityData from "@/components/Accounting/Profile/SecurityData";
import NotificationsData from "@/components/Accounting/Profile/NotificationsData";
import PermissionData from "@/components/Accounting/Profile/PermissionData";
import PersonalaccesstokenData from "@/components/Accounting/Profile/PersonalaccesstokenData";

export default [
    {
        path: "/",
        name: "dashboard",
        component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/DashboardPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: "Dashboard",
        }
    }, {
        path: "/profile",
        name: "customer-profile",
        children: [{
            path: "",
            name: "profile-personal-data",
            component: () => import(/* webpackChunkName: "Profile" */ "@/pages/Accounting/Profile/ProfilePage.vue"),
            props: {
                activeComponent: PersonalData,
            },
            meta: {
                middleware: ['authenticated'],
                title: "Mein Profil",
            }
        }, {
            path: "security",
            name: "profile-security",
            component: () => import(/* webpackChunkName: "Profile" */ "@/pages/Accounting/Profile/ProfilePage.vue"),
            props: {
                activeComponent: SecurityData,
            },
            meta: {
                middleware: ['authenticated'],
                title: "Mein Profil - Security",
            },
        }, {
            path: "personal-access-token",
            name: "personal-access-token",
            component: () => import(/* webpackChunkName: "Profile" */ "@/pages/Accounting/Profile/ProfilePage.vue"),
            props: {
                activeComponent: PersonalaccesstokenData,
            },
            meta: {
                middleware: ['authenticated'],
                title: "Mein Profil - API Token",
            },
        },{
            path: "permissions",
            name: "permissions",
            component: () => import(/* webpackChunkName: "Profile" */ "@/pages/Accounting/Profile/ProfilePage.vue"),
            props: {
                activeComponent: PermissionData
            },
            meta: {
                middleware: ['authenticated'],
                title: "Mein Profil - Berechtigungen",
            },
        },{
            path: "notifications",
            name: "notifications",
            component: () => import(/* webpackChunkName: "Profile" */ "@/pages/Accounting/Profile/ProfilePage.vue"),
            props: {
                activeComponent: NotificationsData,
            },
            meta: {
                middleware: ['authenticated'],
                title: "Mein Profil - Benachrichtigungen",
            },
        }]
    },
];