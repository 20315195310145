<template>
    <div class="partner-card mb-2 card">
        <div class="partner-card-header">
            <h5>{{ $t('partner.sales_material.title') }}</h5>
            <p>
                {{ $t('partner.sales_material.description') }}
            </p>
        </div>
        <MoleculeTable class="mb-0 partner-card-table table-layout-dynamic">
            <AtomTablePart type="body">
                <template v-for="i in 6" :key="`partner-sales-material-row-${i}`">
                    <AtomTableRow>
                        <template v-if="salesMaterial.length > 0 && salesMaterial[i]">
                            <AtomTableCell>
                                <div>
                                    <strong class="partnerlist-overflow-ellipsis">
                                        {{ salesMaterial[i-1].displayName }}
                                    </strong>
                                </div>
                                <div>
                                    <small>{{ $d(salesMaterial[i-1].date, 'short') }}</small>
                                </div>
                            </AtomTableCell>
                            <AtomTableCell>
                                <AtomIcon class="cursor-pointer" name="download-line" @click="downloadSalesMaterial(salesMaterial[i-1])" />
                            </AtomTableCell>
                        </template>
                        <template v-else>
                            <AtomTableCell :colspan="2"></AtomTableCell>
                        </template>
                    </AtomTableRow>
                </template>
            </AtomTablePart>
        </MoleculeTable>
    </div>
    <AtomButton :to="{name: 'partner-sales-material'}">
        {{ $t('partner.sales_material.show_all') }}
    </AtomButton>
</template>
<script>
    import PartnerService from "@/services/Api/Accounting/PartnerService";

    export default {
        name: 'SalesMaterial',
        props: {
            salesMaterial: {
                type: Array,
                default: () => []
            },
            currentYear: {
                type: Number,
                default: new Date().getFullYear()
            },
            currentCustomer: {
                type: Object,
                default: () => {}
            }
        },
        methods: {
            async downloadSalesMaterial(material) {
                return await PartnerService.downloadSalesMaterial({lang: material.lang, basename: material.basename}).then(response => {
                    window.downloadPdf(response.data, material.basename);
                });
            }
        }
    };
</script>