export default {
    de: {
        title: 'Verträge',
        headline: 'Vertrag {number}',
        cluster: {
            empty_table: 'Es sind keine Cluster vorhanden.',
            contract_number: 'Vertragsnummer',
            cluster: 'Cluster',
            customer: 'Kunde',
            customer_number: 'Kundennummer',
            cluster_label: 'Cluster',
            architecture: 'Architektur',
            sla: 'SLA',
            monthly_fee: 'Monatliche Grundgebühr (netto)',
            expected_invoice: 'Vorläufige Rechnung',
            name: 'Name',
            distribution: 'Distribution',
            application: 'Anwendung',
            architecture_and_sla: 'Architektur und SLA',
            hardware: 'Hardware',
            server: 'Server',
            cpu: 'CPU',
            ram: 'RAM',
            nvme: 'NVMe',
            in_architecture_included: 'in Architektur enthalten',
            nvme_sdd: 'NVMe SSD',
            nvme_database: 'NVMe Database',
            nvme_storage: 'NVMe Storage',
            nvme_last_day: '{value} GB (Stand vom Vortag)',
            storage: 'Storage',
            nvme_ssd: 'NVMe SSD',
            search: 'Vertragsnummer, Cluster, Kunde...'
        },
        domain: {
            empty_table: 'Es sind keine Domains vorhanden.',
            contract_number: 'Vertragsnummer',
            domain: 'Domain',
            registration: 'Registrierung',
            contract_start: 'Vertragsbeginn',
            domain_owner: 'Domaininhaber',
            basic_fee: 'Grundgebühr (netto)',
            search: 'Vertragsnummer, Domain...',
            edit_handles: 'Handles bearbeiten',
            regular_yearly_fee: 'Reguläre jährliche Grundgebühr',
            annual_premium_domain_amount: 'Jährliche Premium Domain Zusatzkosten',
            one_time_premium_domain_amount: 'Einmalige Premium Domain Registrierung',
            termination_date: 'Kündigungsdatum',
            auth_code: 'Auth-Code',
            auth_code_copied: 'Auth-Code wurde in die Zwischenablage kopiert.',
            company: 'Unternehmen',
            owner: 'Inhaber',
            handle: 'Handle'
        },
        general: 'Allgemein',
        contract: 'Vertrag',
        contract_start: 'Vertragsbeginn',
        contract_type: 'Vertragstyp',
        modus: {
            production: 'Produktiv'
        },
        invoice_description: 'Eine detaillierte Kostenaufstellung für den laufenden Monat entnehmen Sie bitte der vorläufigen Rechnung <a href="{url}">{invoice}</a>. Diese berücksichtigt die tatsächliche Skalierung und den Storage-Bedarf der einzelnen Tage.',
        total: 'Gesamt (netto)',
        status: {
            active: 'Aktiv',
            in_termination: 'in Kündigung',
            terminated: 'Gekündigt',
            completed: 'Abgeschlossen',
            cancelled: 'Abgebrochen',
            failed: 'Fehlgeschlagen',
            pending: 'Ausstehend'
        },
        contract_details: 'Vertragsdetails',
        download_form: 'Formular herunterladen',
        termination_select_label: 'Laufzeitende auswählen',
        termination_send: 'Kündigen',
        domain_termination_text: ' Der Vertrag wird zum Ende der aktuellen Registrierungsperiode gekündigt. Die Domain "{domain}" wird entsprechend zu diesem Datum gelöscht. Um die Domain vorab zu einem anderen Anbieter umzuziehen, können Sie den Auth-Code verwenden. Dieser wird Ihnen nach der Kündigung in den Vertragsdetails angezeigt. Bitte beachten Sie: Die Kosten für die verbleibende Laufzeit können Ihnen nicht rückerstattet werden ',
        domain_termination_check: 'Ja, ich möchte den Vertrag für die Domain "{domain}" jetzt kündigen.',
        termination_success: 'Die Kündigung für den Vertrag wurde erfolgreich ausgeführt. Es wurde Ihnen eine E-Mail mit den Auth-Code zugesendet. Sie finden ihn außerdem in der Detailansicht des Vertrags.',
        termination: {
            title: 'Kündigung {number}',
            title_in_termination: 'Kündigung {number} - in Kündigung',
            domain_contracts: 'Domainverträge',
            please_terminate_domain_contracts: 'Bitte beachten Sie: Falls Sie keinen aktiven Cluster-Vertrag mehr haben, müssen auch laufende Domain-Verträge gekündigt werden.',
            email: 'E-Mail',
            terminate_emails: 'Die Postfächer werden automatisch mit gelöscht.',
            termination_date: 'Laufzeitende',
            termination_reason: 'Kündigungsgrund',
            confirm_termination_title: 'Vertrag {number} kündigen',
            confirm_termination_text: '<p>Hiermit bestätige ich, die Kündigung des Vertrags {contractNumber} für Cluster {clusterNumber} zum {periodEndDate}.</p><p>Ich bin darüber informiert, dass die über den Vertrag {contractNumber} betriebenen Server von Cluster {clusterNumber} zum Vertragslaufzeitende am {periodEndDate} abgeschaltet werden.</p><p>Ich bin damit einverstanden, dass alle Daten und alle Backups dieser Infrastruktur zum {deletionDate} gelöscht werden.</p>',
            date_of_shutdown: 'Datum der Abschaltung',
            date_of_deletion: 'Datum der Löschung',
            list_headline: 'Kündigung',
            no_domain_contracts: 'Es gibt keine offenen Domainverträge.',
            success_create: 'Die Kündigung wurde erfolgreich erstellt.',
            success_update: 'Die Kündigung wurde erfolgreich aktualisiert.',
            reasons: {
                contract_change: 'Vertragswechsel',
                provider_change: 'Providerwechsel',
                closure: 'Geschäftsaufgabe',
                relocation: 'Umzug',
                other: 'Sonstiges',
                payment_default: 'Zahlungsverzug',
                insolvency: 'Insolvenz',
                violation_of_terms_and_conditions_or_guidelines: 'Verstoß gegen AGB oder Richtlinien'
            },
            title_abort: 'Kündigung abbrechen',
            description_abort: 'Möchten Sie die Kündigung wirklich abbrechen?',
            abort_success: 'Die Kündigung wurde erfolgreich abgebrochen.',
            title_confirm: 'Kündigung bestätigen',
            description_confirm: 'Möchten Sie die Kündigung bestätigen?',
            confirm_success: 'Die Kündigung wurde erfolgreich bestätigt.',
            already_exists: 'Es existiert bereits eine Kündigung für diesen Vertrag.',
        },
    },
    en: {
        title: 'Contracts',
        headline: 'Contract {number}',
        cluster: {
            empty_table: 'No clusters available.',
            contract_number: 'Contract number',
            cluster: 'Cluster',
            customer: 'Customer',
            customer_number: 'Customer number',
            cluster_label: 'Cluster',
            architecture: 'Architecture',
            sla: 'SLA',
            monthly_fee: 'Monthly fee (net)',
            expected_invoice: 'Preliminary invoice',
            name: 'Name',
            distribution: 'Distribution',
            application: 'Application',
            architecture_and_sla: 'Architecture and SLA',
            hardware: 'Hardware',
            server: 'Server',
            cpu: 'CPU',
            ram: 'RAM',
            nvme: 'NVMe',
            in_architecture_included: 'included in architecture',
            nvme_sdd: 'NVMe SSD',
            nvme_database: 'NVMe database',
            nvme_storage: 'NVMe Storage',
            nvme_last_day: '{value} GB (Status from previous day)',
            storage: 'Storage',
            nvme_ssd: 'NVMe SSD',
            search: 'Contract number, cluster, customer...'
        },
        domain: {
            empty_table: 'There are no domains available.',
            contract_number: 'Contract number',
            domain: 'Domain',
            registration: 'Registration',
            contract_start: 'Contract start',
            domain_owner: 'Domain owner',
            basic_fee: 'Basic fee (net)',
            search: 'Contract number, domain...',
            edit_handles: 'Edit handles',
            regular_yearly_fee: 'Regular yearly fee',
            annual_premium_domain_amount: 'Annual premium domain amount',
            one_time_premium_domain_amount: 'One-time premium domain registration',
            termination_date: 'Termination date',
            auth_code: 'Auth-Code',
            auth_code_copied: 'Auth-Code has been copied to the clipboard.',
            company: 'Company',
            owner: 'Owner',
            handle: 'Handle'
        },
        general: 'General',
        contract: 'Contract',
        contract_start: 'Start of contract',
        contract_type: 'Contract type',
        modus: {
            production: 'Productive'
        },
        invoice_description: 'For a detailed cost breakdown for the current month, please refer to the preliminary invoice <a href="{url}">{invoice}</a>. This takes into account the actual scaling and storage requirements for each day.',
        total: 'Total (net)',
        status: {
            active: 'Active',
            in_termination: 'In termination',
            terminated: 'Terminated',
            completed: 'Completed',
            cancelled: 'Cancelled',
            failed: 'Failed',
            pending: 'Pending'
        },
        contract_details: 'Contract details',
        download_form: 'Download form',
        termination_select_label: 'Select end of term',
        termination_send: 'Terminate',
        domain_termination_text: 'The contract will be terminated at the end of the current registration period. The domain "{domain}" will be deleted accordingly on this date. To transfer the domain to another provider in advance, you can use the Auth-Code. This will be displayed in the contract details after termination. Please note: The costs for the remaining term cannot be refunded.',
        domain_termination_check: 'Yes, I want to cancel the contract for the domain "{domain}" now.',
        termination_success: 'The contract has been successfully terminated. An e-mail with the auth-code has been sent to you. You can also find it in the detailed view of the contract.',
        termination: {
            title: 'Termination {number}',
            title_in_termination: 'Termination {number} - in termination',
            domain_contracts: 'Domain contracts',
            please_terminate_domain_contracts: 'Please note: If you no longer have an active cluster contract, you must also terminate any ongoing domain contracts.',
            email: 'E-Mail',
            terminate_emails: 'The mailboxes will be automatically deleted.',
            termination_date: 'End of term',
            termination_reason: 'Reason for termination',
            confirm_termination_title: 'Terminate contract {number}',
            confirm_termination_text: '<p>I hereby confirm the termination of contract {contractNumber} for cluster {clusterNumber} on {periodEndDate}.</p><p>I am aware that the servers operated via contract {contractNumber} for cluster {clusterNumber} will be shut down at the end of the contract period on {periodEndDate}.</p><p>I agree that all data and all backups of this infrastructure will be deleted on {deletionDate}.</p>',
            date_of_shutdown: 'Date of shutdown',
            date_of_deletion: 'Date of deletion',
            list_headline: 'Termination',
            no_domain_contracts: 'There are no open domain contracts.',
            success_create: 'The termination has been successfully created.',
            success_update: 'The termination has been successfully updated.',
            reasons: {
                contract_change: 'Change of contract',
                provider_change: 'Change of provider',
                closure: 'Closure',
                relocation: 'Relocation',
                other: 'Other',
                payment_default: 'Late payment',
                insolvency: 'Insolvency',
                violation_of_terms_and_conditions_or_guidelines: 'Violation of terms and conditions or guidelines'
            },
            title_abort: 'Abort termination',
            description_abort: 'Do you really want to cancel the termination?',
            abort_success: 'The termination has been successfully canceled.',
            title_confirm: 'Confirm termination',
            description_confirm: 'Do you want to confirm the termination?',
            confirm_success: 'The termination has been successfully confirmed.',
            already_exists: 'A termination for this contract already exists.',
        },
    }
};
