<template>
    <div class="row" v-bind="$attrs">
        <div class="col-12">
            <h4>{{ $t('profile.permissions.title') }}</h4>
            <hr v-if="permissions.length > 0">
        </div>
        <template v-if="permissions.length > 0">
            <div class="col-8"></div>
            <div class="col-4">
                <AtomInput
                    v-model="search"
                    :placeholder="$t('profile.permissions.search')"
                    :disabled="actionIsRunning"
                    prepend
                >
                    <template #input-prepend>
                        <AtomButton>
                            <AtomIcon name="search-line" />
                        </AtomButton>
                    </template>
                </AtomInput>
            </div>
        </template>
        <div class="col-12 mt-2">
            <p v-if="permissions.length === 0">{{ $t('profile.permissions.no_permissions') }}</p>
            <AutoSortableTable
                v-else
                :content="permissionsList"
                :configuration="tableConfiguration"
                pagination
                initial-sorting-key="cluster"
            >
                <template #column-cluster="permission">
                    <template v-if="permission.cluster">
                        {{ permission.cluster.number }} - {{ permission.cluster.name }}
                    </template>
                    <template v-else>-</template>
                </template>
                <template #column-company="permission">
                    <template v-if="permission.company && permission.number">
                        {{ permission.number }} - {{ permission.company }}
                    </template>
                    <template v-else>-</template>
                </template>
                <template #column-role="permission">
                    <template v-if="permission.company && permission.number">
                        {{ $t('profile.permissions.role_manager') }}
                    </template>
                    <template v-else-if="permission.cluster">
                        {{ $t('profile.permissions.role_administrator') }}
                    </template>
                    <template v-else>-</template>
                </template>
                <template #column-menu="permission">
                    <DotMenu>
                        <AtomDropdownItem class="danger" @click.prevent="openDelete(permission)">{{ $t('helper.actions.delete') }}</AtomDropdownItem>
                    </DotMenu>
                </template>
            </AutoSortableTable>
        </div>
    </div>
    <MoleculeModal :close-text="$t('helper.actions.abort')" @close="showDelete = false" :show-modal="showDelete" v-model="showDelete" size="md">
        <template #modal-headline>
            <h3  v-if="permission.company && permission.number">{{ $t('profile.permissions.delete.title_manager') }}</h3>
            <h3 v-else>{{ $t('profile.permissions.delete.title_administrator') }}</h3>
        </template>
        <template #content>
            <p v-if="permission.company && permission.number">{{ $t('profile.permissions.delete.description_manager', {number: permission?.number}) }}</p>
            <p v-else>{{ $t('profile.permissions.delete.description_administrator', {number: permission?.number}) }}</p>
        </template>
        <template #actions>
            <AtomButton @click.prevent="deletePermission">{{ $t('helper.actions.delete') }}</AtomButton>
        </template>
    </MoleculeModal>
</template>
<script>
    import {ResponseService, UserService, userStore} from "@development/cluster-center";
    import BaseComponent from "@/components/BaseComponent.vue";

    export default {
        extends: BaseComponent,
        name: "PermissionData",
        data() {
            return {
                user: null,
                permissions: [],
                showDelete: false,
                permission: {},
                search: null
            };
        },
        computed: {
            tableConfiguration() {
                return [
                    {key: 'cluster', title: this.$i18n.t('profile.permissions.table.cluster'), sortable: true, sortVal: item => {
                        if (item.cluster) {
                            return item.cluster.id + ' - ' + item.cluster.name;
                        }

                        return '-';
                    }},
                    {key: 'company', title: this.$i18n.t('profile.permissions.table.customer'), sortable: true, sortVal: item => {
                        if (item.company && item.number) {
                            return item.number + ' - ' + item.company;
                        }

                        return '-';
                    }},
                    {key: 'role', title: this.$i18n.t('profile.permissions.table.role'), sortable: true, sortVal: item => {
                        if (item.company && item.number) {
                            return this.$t('profile.permissions.role_manager');
                        }

                        if (item.cluster) {
                            return this.$t('profile.permissions.role_administrator');
                        }

                        return '-';
                    }},
                    {key: 'menu', title: '', width: 75},
                ];
            },
            permissionsList() {
                if (!this.search || this.search === "") {
                    return this.permissions || [];
                }

                return this.permissions.filter(permission => {
                    let cluster = (permission.cluster) ? permission.cluster.id + ' - ' + permission.cluster.name : '-';
                    let company = (permission.company && permission.number) ? permission.number + ' - ' + permission.company : '-';
                    let role = '-';

                    if (permission.company && permission.number) {
                        role = this.$t('profile.permissions.role_manager');
                    } else if (permission.cluster) {
                        role = this.$t('profile.permissions.role_administrator');
                    }

                    return (
                        cluster.toLowerCase().includes(this.search.toLowerCase()) ||
                        company.toLowerCase().includes(this.search.toLowerCase()) ||
                        role.toLowerCase().includes(this.search.toLowerCase())
                    );
                });
            },
        },
        mounted() {
            const i18n = window.Config.get('i18n');
            this.user = userStore();
            this.actionIsRunning = true;

            let promises = [this.getAdministratedContracts(), this.getManagedCustomers()];

            Promise.all(promises).then(([administrated, managed]) => {
                if (administrated.error || managed.error) {
                    let errorData = administrated.error ? administrated.data : managed.data;

                    if (errorData[2] !== 401) {
                        ResponseService.danger(i18n.t(errorData[1], {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email}));
                    }

                    return;
                }

                this.permissions = [...administrated.data, ...managed.data];
            }).finally(() => {
                this.actionIsRunning = false;
            });
        },
        methods: {
            async getManagedCustomers() {
                return await UserService.showManagedCustomers().then(response => {
                    if (Array.isArray(response)) {
                        return {data: response, error: true};
                    }

                    return {data: response.data, error: false};
                });
            },
            async getAdministratedContracts() {
                return await UserService.showAdministratedContracts().then(response => {
                    if (Array.isArray(response)) {
                        return {data: response, error: true};
                    }

                    return {data: response.data, error: false};
                });
            },
            async deletePermission() {
                return await this[`revoke${this.permission.cluster ? 'Contract' : 'Customer'}`]();
            },
            async revokeCustomer() {
                return await UserService.revokeCustomer({email: this.user.email, customer_id: this.permission.id}).then(() => {
                    ResponseService.globalNotify(this.$i18n.t('profile.permissions.revoke_customer_success'), 'success');
                    this.showDelete = false;
                    this.permission = {};
                });
            },
            async revokeContract() {
                return await UserService.revokeContract({email: this.user.email, contract_id: this.permission.id}).then(() => {
                    ResponseService.globalNotify(this.$i18n.t('profile.permissions.revoke_contract_success'), 'success');
                    this.showDelete = false;
                    this.permission = {};
                });
            },
            openDelete(permission) {
                this.permission = permission;
                this.showDelete = true;
            }
        }
    };
</script>
