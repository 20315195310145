export default {
    de: {
        title: 'Meine Daten',
        navigation: {
            personal_data: 'Persönliche Daten',
            security: 'Sicherheit',
            pat: 'Personal Access Token',
            permissions: 'Berechtigungen',
            notifications: 'Benachrichtigungen',
        },
        personal_data: {
            gender_label: 'Anrede',
            male: 'Herr',
            female: 'Frau',
            diverse: 'Divers',
            gender: 'Geschlecht',
            email_address: 'E-Mail-Adresse',
            email: 'E-Mail',
            change_email: 'E-Mail-Adresse ändern',
            change_email_information: 'Die Änderung Ihrer E-Mail-Adresse zu <strong>{email}</strong> muss noch bestätigt werden.',
            contact_data: 'Kontaktdaten',
            firstname: 'Vorname',
            surname: 'Nachname',
            phone: 'Festnetz',
            mobile: 'Mobil',
            company: 'Unternehmen',
            department: 'Abteilung',
            street_and_number: 'Straße und Hausnummer',
            postcode: 'PLZ',
            city: 'Stadt',
            save_data: 'Angaben speichern',
            language: 'Sprache',
            password: 'Passwort',
            new_email: 'Neue E-Mail-Adresse',
            changed_email_success: 'Die Änderung Ihrer E-Mail-Adresse zu {email} muss noch bestätigt werden.',
            update_success: 'Ihr Profil wurde erfolgreich gespeichert.',
            country: 'Land',
            verify_email_address: {
                headline: 'E-Mail Adresse bestätigen',
                confirmation_code: 'Bestätigungscode',
                warning: '<strong>Bestätigung Ihrer E-Mail-Adresse</strong><br>Um sicherzustellen, dass Ihre Kontoinformationen korrekt sind, muss diese Adresse bestätigt werden. Bitte überprüfen Sie daher Ihren E-Mail-Posteingang und geben Sie den erhaltenen Bestätigungscode ein, um Ihre E-Mail-Adresse zu bestätigen.',
                send_verification_code: 'Der Bestätigungscode wurde verschickt. Bitte prüfen Sie Ihr E-Mail-Postfach.',
                success: 'Ihre E-Mail-Adresse wurde erfolgreich bestätigt.',
                dashboard_warning: '<strong>Wichtig: E-Mail-Verifizierung erforderlich</strong><br>Um die Sicherheit Ihres Kontos zu gewährleisten, führen wir eine obligatorische E-Mail-Verifizierung durch. Bitte überprüfen Sie Ihren E-Mail-Posteingang und geben Sie den erhaltenen Bestätigungscode im Application Center ein, um Ihre E-Mail-Adresse zu bestätigen. Sie haben die E-Mail nicht erhalten? ',
                dashboard_warning_link_text: 'Hier klicken zum erneuten Senden.'
            },
            individual_settings: 'Individuelle Einstellungen',
            reduced_width: 'Reduzierte Breite',
            ui: 'Oberfläche'
        },
        security: {
            password: 'Passwort',
            change_password: 'Passwort ändern',
            password_old: 'Altes Passwort',
            password_new: 'Neues Passwort',
            password_confirmation: 'Neues Passwort wiederholen',
            changed_password_success: 'Das Passwort wurde erfolgreich geändert.'
        },
        pat: {
            title: 'Personal Access Tokens',
            search: 'Suche',
            description: '<strong>Hinweis:</strong> Personal Access Tokens können zur Authentifizierung in cluster-control verwendet werden. Zudem können sie als Bearer-Token im Header von API-Anfragen zur Authentifizierung gesendet werden. Die API inklusive der Dokumentation stehen nicht offiziell zur Verfügung - die Verwendung geschieht daher auf eigene Verantwortung - dh. die API kann sich zu jeder Zeit unangekündigt ändern - es können sich Anfragen und Antworten ändern, Routen hinzukommen, ändern oder ganz entfallen.',
            table: {
                token: 'Token',
                name: 'Name',
                restriction: 'Beschränkung',
                expiry_date: 'Ablaufdatum',
                last_uses: 'Zuletzt verwendet',
            },
            form: {
                name: 'Name',
                access_areas: 'Zugriffsbereiche',
                full_access: 'API Vollzugriff',
                all_cluster: 'alle Cluster (Managed-Center, ...)',
                profile_data: 'Profildaten, Benutzerverwaltung',
                invoices: 'Buchhaltung (Verträge, Rechnungen, ...)',
                limited_api_access: 'Eingeschränkter API Zugriff',
                access_to_cluster: 'Zugriff auf folgende Cluster',
                validity: 'Gültigkeit',
                until: 'bis zum',
                select_all: 'Alle auswählen',
                success: 'Der Personal Access Token wurde erfolgreich erstellt.',
            },
            unlimited: 'unbegrenzt',
            no_tokens: 'Es sind keine Personal Access Tokens vorhanden.',
            title_api_key: 'API-Key',
            api_key: 'API-Key',
            deprecated_api_key: '<strong>API Keys werden zum 01.04.2023 eingestellt.</strong> Aus diesem Grund können ab dem 01.03.2023 keine neuen API Keys mehr erstellt werden. Anstelle von API Keys können Sie Personal Access Tokens zur Authentifizierung in cluster-control oder an der API nutzen. Personal Access Tokens ersetzen API Keys, erfordern keinen vorherigen Login und bieten darüber hinaus weitere Vorteile wie ein Ablaufdatum oder eine Beschränkung auf API Bereiche.',
            none: 'Keine',
            delete: {
                title: 'Personal Access Token entfernen',
                description: 'Möchten Sie den Personal Access Token ({name}) wirklich entfernen?',
                success: 'Der Personal Access Token wurde erfolgreich entfernt.'
            },
            info: 'Bitte beachten Sie: Dieser Token wird nur einmalig angezeigt. Bitte bewahren Sie ihn daher sicher auf.',
            delete_api_key_success: 'Der API-Key wurde erfolgreich gelöscht.',
            copied: 'Der PAT wurde in die Zwischenablage kopiert.'
        },
        permissions: {
            title: 'Berechtigungen',
            role_manager: 'Manager',
            search: 'Suche',
            role_administrator: 'Administrator',
            table: {
                cluster: 'Cluster',
                customer: 'Kunde',
                role: 'Rolle'
            },
            delete: {
                title_manager: 'Manager-Berechtigung entfernen',
                title_administrator: 'Administrator-Berechtigung entfernen',
                description_manager: 'Möchten Sie sich die Manager-Berechtigung für Kunde {number} wirklich entfernen?',
                description_administrator: 'Möchten Sie sich die Administrator-Berechtigung für Cluster {number} wirklich entfernen?'
            },
            no_permissions: 'Keine Berechtigungen vorhanden.',
            revoke_customer_success: 'Die Manager-Berechtigung wurde erfolgreich entfernt.',
            revoke_contract_success: 'Die Administrator-Berechtigung wurde erfolgreich entfernt.'
        },
        notifications: {
            title: 'Benachrichtigungen',
            option: 'Technische Benachrichtigungen z.B. Skalierungsmails'
        },
        two_factor: {
            title: 'Zwei-Faktor-Authentifizierung',
            title_activate: 'Zwei-Faktor-Authentifizierung aktivieren',
            button_activate: 'Zwei-Faktor-Authentifizierung aktivieren',
            button_deactivate: 'Zwei-Faktor-Authentifizierung deaktivieren',
            show_recovery_codes: 'Recovery Codes anzeigen',
            button_add_phone_number: 'Wiederherstellungsnummer hinzufügen',
            button_edit_phone_number: 'Wiederherstellungsnummer ändern',
            button_remove_phone_number: 'Wiederherstellungsnummer entfernen',
            current_number: 'Aktuelle Wiederherstellungsnummer',
        }
    },
    en: {
        title: 'My data',
        navigation: {
            personal_data: 'Personal data',
            security: 'Security',
            pat: 'Personal Access Token',
            permissions: 'Permissions',
            notifications: 'Notifications',
        },
        personal_data: {
            gender_label: 'Salutation',
            male: 'Mr.',
            female: 'Mrs.',
            diverse: 'Diverse',
            gender: 'Gender',
            email_address: 'Email address',
            email: 'Email',
            change_email: 'Change email address',
            change_email_information: 'The change of your email address to <strong>{email}</strong> still needs to be confirmed.',
            contact_data: 'Contact details',
            firstname: 'First name',
            surname: 'Surname',
            phone: 'Telephone',
            mobile: 'Mobile',
            company: 'Company',
            department: 'Department',
            street_and_number: 'Street and house number',
            postcode: 'Postcode',
            city: 'City',
            save_data: 'Save data',
            language: 'Language',
            password: 'Password',
            new_email: 'New email address',
            changed_email_success: 'The change of your email address to {email} still needs to be confirmed.',
            update_success: 'Your profile has been saved successfully.',
            country: 'Country',
            verify_email_address: {
                headline: 'Verify email address',
                confirmation_code: 'Confirmation code',
                warning: '<strong>Confirmation of your email address</strong><br>To ensure that your account information is correct, this address must be confirmed. Please check your email inbox and enter the confirmation code you received to confirm your email address.',
                send_verification_code: 'The confirmation code has been sent. Please check your email inbox.',
                success: 'Your email address has been successfully confirmed.',
                dashboard_warning: '<strong>Important: Email verification required</strong><br>To ensure the security of your account, we are conducting mandatory email verification. Please check your email inbox and enter the confirmation code received in the Application Center to confirm your email address. Did not receive the email? ',
                dashboard_warning_link_text: 'Click here to resend.'
            },
            individual_settings: 'Individual settings',
            reduced_width: 'Reduced width',
            ui: 'User interface'
        },
        security: {
            password: 'Password',
            change_password: 'Change password',
            password_old: 'Old password',
            password_new: 'New password',
            password_confirmation: 'Repeat new password',
            changed_password_success: 'The password has been changed successfully.'
        },
        pat: {
            title: 'Personal Access Tokens',
            search: 'Search',
            description: '<strong>Note:</strong> Personal Access Tokens can be used for authentication in cluster-control. In addition, they can be sent as bearer tokens in the header of API requests for authentication. The API including the documentation is not officially available - so use it on your own responsibility - i.e. the API can change at any time without notice - requests and responses can change, routes can be added, changed or dropped completely.',
            table: {
                token: 'Token',
                name: 'Name',
                restriction: 'Restriction',
                expiry_date: 'Expiration date',
                last_uses: 'Last used',
            },
            form: {
                name: 'Name',
                access_areas: 'Access areas',
                full_access: 'API full access',
                all_cluster: 'all clusters (managed centers, ...)',
                profile_data: 'Profile data, user management',
                invoices: 'Accounting (contracts, invoices, ...)',
                limited_api_access: 'Restricted API access',
                access_to_cluster: 'Access to the following clusters',
                validity: 'Validity',
                until: 'until',
                select_all: 'Select all',
                success: 'The Personal Access Token was successfully created.'
            },
            unlimited: 'unlimited',
            no_tokens: 'There are no Personal Access Tokens.',
            title_api_key: 'API-Key',
            api_key: 'API-Key',
            deprecated_api_key: '<strong>API Keys will be discontinued on 01.04.2023.</strong> For this reason, no new API Keys can be created from 01.03.2023. Instead of API Keys, you can use Personal Access Tokens for authentication in cluster-control or at the API. Personal Access Tokens replace API Keys, do not require a previous login and also offer other advantages such as an expiration date or a restriction to API areas.',
            none: 'None',
            delete: {
                title: 'Remove Personal Access Token',
                description: 'Do you really want to remove the Personal Access Token ({name})?',
                success: 'The Personal Access Token was successfully removed.'
            },
            info: 'Please note: This token is only displayed once. Therefore, please keep it in a safe place.',
            delete_api_key_success: 'The API key was successfully deleted.',
            copied: 'The PAT has been copied to the clipboard.'
        },
        permissions: {
            title: 'Permissions',
            role_manager: 'Manager',
            role_administrator: 'Administrator',
            search: 'Search',
            table: {
                cluster: 'Cluster',
                customer: 'Customer',
                role: 'Role'
            },
            delete: {
                title_manager: 'Remove manager permission',
                title_administrator: 'Remove administrator permission',
                description_manager: 'Do you really want to remove the manager permission for customer {number}?',
                description_administrator: 'Do you really want to remove the administrator permission for cluster {number}?'
            },
            no_permissions: 'No permissions available.',
            revoke_customer_success: 'The manager permission was successfully removed.',
            revoke_contract_success: 'The administrator permission was successfully removed.'
        },
        notifications: {
            title: 'Notifications',
            option: 'Technical notifications e.g. scaling emails'
        },
        two_factor: {
            title: 'Two-factor authentication',
            title_activate: 'Enable two-factor authentication',
            button_activate: 'Enable two-factor authentication',
            button_deactivate: 'Disable two-factor authentication',
            show_recovery_codes: 'Show recovery codes',
            button_add_phone_number: 'Add recovery phone number',
            button_edit_phone_number: 'Change recovery phone number',
            button_remove_phone_number: 'Remove recovery phone number',
            current_number: 'Current recovery phone number',
        }
    }
};
