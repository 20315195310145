<template>
    <ChangePassword />
    <div class="row mt-4">
        <div class="col-12"><hr /></div>
        <div class="col-12 mb-3">
            <h4>
                {{ $t('profile.two_factor.title') }}
                <AtomBadge variant="success" size="sm" v-if="userStates.twoFactorEnabled === true">{{ $t('helper.status.active') }}</AtomBadge>
                <AtomBadge variant="secondary" size="sm" v-else>{{ $t('helper.status.deactivated') }}</AtomBadge>
            </h4>
        </div>
        <div class="col-12 d-flex">
            <AtomButton class="me-3" @click.prevent="showTwoFactorRecoveryPhoneNumberForm = true" v-if="userStates.twoFactorEnabled === true && userStates.twoFactorReoveryPhoneNumber === undefined">{{ $t('profile.two_factor.button_add_phone_number') }}</AtomButton>
            <AtomButton @click.prevent="showTwoFactorForm = true" v-if="userStates.twoFactorEnabled === false || userStates.twoFactorConfirmed === false">{{ $t('profile.two_factor.button_activate') }}</AtomButton>
            <AtomButton @click.prevent="showTwoFactorRecoveryCodes = true" v-if="userStates.twoFactorEnabled === true && userStates.twoFactorConfirmed === true">{{ $t('profile.two_factor.show_recovery_codes') }}</AtomButton>
            <AtomButton class="ms-3" variant="secondary" @click.prevent="showTwoFactorDisable = true" v-if="userStates.twoFactorEnabled === true">{{ $t('profile.two_factor.button_deactivate') }}</AtomButton>
        </div>
        <template v-if="userStates.twoFactorReoveryPhoneNumber !== undefined">
            <div class="col-12 mt-2">
                <hr/>
                <h4>{{ $t('profile.two_factor.current_number') }}</h4>
            </div>
            <div class="col-3">
                <AtomInput disabled v-model="number" />
            </div>
            <div class="col d-flex">
                <AtomButton class="me-3" @click.prevent="showTwoFactorRecoveryPhoneNumberForm = true">
                    {{ $t('profile.two_factor.button_edit_phone_number') }}
                </AtomButton>
                <AtomButton variant="secondary" @click.prevent="showRemoveNumber = true">
                    {{ $t('profile.two_factor.button_remove_phone_number') }}
                </AtomButton>
            </div>
        </template>
    </div>
    <RecoveryPhoneNumber @updated:two-factor="getInformation" @close="showTwoFactorRecoveryPhoneNumberForm = false" :show="showTwoFactorRecoveryPhoneNumberForm" />
    <RemoveRecoveryPhoneNumber @updated:two-factor="getInformation" @close="showRemoveNumber = false" :show="showRemoveNumber" />
    <TwoFactorForm @updated:two-factor="activatedTwoFactor" @close="showTwoFactorForm = false" :show="showTwoFactorForm" />
    <TwoFactorRecoveryCodes :password="password" :success-message="showSuccessActivated" @close="closeRecoveryCodes" :show="showTwoFactorRecoveryCodes" />
    <TwoFactorDisable @updated:two-factor="$emit('updated:user')" @close="showTwoFactorDisable = false" :show="showTwoFactorDisable" />
</template>
<script>
    import ChangePassword from "@/components/Accounting/Profile/Forms/ChangePasswordForm";
    import {UserService, userStore} from "@development/cluster-center";

    export default {
        emits: ['updated:user'],
        name: 'SecurityData',
        components: {
            ChangePassword,
        },
        data() {
            return {
                showTwoFactorForm: false,
                showTwoFactorRecoveryCodes: false,
                showTwoFactorDisable: false,
                password: '',
                newPassword:'',
                newPasswordRepeat:'',
                user: {},
                userStates: {},
                showSuccessActivated: false,
                showTwoFactorRecoveryPhoneNumberForm: false,
                number: '',
                showRemoveNumber: false
            };
        },
        mounted() {
            this.user = userStore();
            const userState = this.user?.$state;
            this.userStates = userState;
            this.getInformation();
        },
        methods: {
            closeRecoveryCodes() {
                this.showTwoFactorRecoveryCodes = false;
                this.showSuccessActivated = false;
                this.password = '';
            },
            activatedTwoFactor(pwd) {
                this.showSuccessActivated = true;
                this.showTwoFactorRecoveryCodes = true;
                this.$emit('updated:user');

                if (typeof pwd === 'string') {
                    this.password = pwd;
                }
            },
            getInformation() {
                this.getTwoFactorReoveryPhoneNumber();
            },
            async getTwoFactorReoveryPhoneNumber() {
                return await UserService.getTwoFactorReoveryPhoneNumber().then(response => {
                    let data = response.data;

                    if (Array.isArray(response) && response[0].status === 409) {
                        data = null;
                    }

                    this.user.$patch({twoFactorReoveryPhoneNumber: data?.phone_number,});
                    this.number = data?.phone_number;
                });
            }
        },
        watch: {
            showTwoFactorRecoveryCodes(val) {
                if (!val) {
                    this.showSuccessActivated = false;
                }
            }
        }
    };
</script>