export default {
    de: {
        notifications: {
            notification_center: {
                titel: 'Notification Center',
                text: 'In unserem neuen Notification Center werden wir Sie ab sofort auf neue Funktionen und Benachrichtigungen im Application Center hinweisen.'
            },
            ui_customization: {
                titel: 'Einstellungen Oberfläche',
                text: 'Wir bieten ab sofort die Möglichkeit, das Application Center im Dark Mode sowie in der ganzen Breite des Screens zu verwenden.'
            },
            table_config: {
                titel: 'Neue Tabellenoptionen',
                text: 'Für eine bessere Übersicht in Tabellen können Tabellenspalten nun individuell ein- und ausgeblendet werden. Dabei ist zu beachten, dass diese Einstellung vorerst nur im Browser gespeichert wird.'
            }
        },
        link: 'Anzeigen'
    },
    en: {
        notifications: {
            notification_center: {
                titel: 'Notification Center',
                text: 'Our new Notification Center will keep you informed about new features and notifications in the Application Center.'
            },
            ui_customization: {
                titel: 'Display options',
                text: 'We now offer the option to use the Application Centre in dark mode and across the full width of the screen.'
            },
            table_config: {
                titel: 'New table options',
                text: 'For a better overview in tables, you can now show and hide table columns individually. Note that this setting is initially only saved in the browser.'
            }
        },
        link: 'Show'
    }
};
