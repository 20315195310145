export function getMonthShortNameList() {
    const i18n = window.Config.get('i18n');

    return [
        i18n.t('helper.short_months.january'),
        i18n.t('helper.short_months.february'),
        i18n.t('helper.short_months.march'),
        i18n.t('helper.short_months.april'),
        i18n.t('helper.short_months.may'),
        i18n.t('helper.short_months.june'),
        i18n.t('helper.short_months.july'),
        i18n.t('helper.short_months.august'),
        i18n.t('helper.short_months.september'),
        i18n.t('helper.short_months.october'),
        i18n.t('helper.short_months.november'),
        i18n.t('helper.short_months.december')
    ];
}

export function getMonthNameList() {
    const i18n = window.Config.get('i18n');

    return [
        i18n.t('helper.months.january'),
        i18n.t('helper.months.february'),
        i18n.t('helper.months.march'),
        i18n.t('helper.months.april'),
        i18n.t('helper.months.may'),
        i18n.t('helper.months.june'),
        i18n.t('helper.months.july'),
        i18n.t('helper.months.august'),
        i18n.t('helper.months.september'),
        i18n.t('helper.months.october'),
        i18n.t('helper.months.november'),
        i18n.t('helper.months.december')
    ];
}