<template>
    <div v-bind="$attrs">
        <template v-if="!isUserEmailVerified">
            <VerifyEmailForm />
            <hr>
        </template>
        <ChangeEmailForm :email="store?.email" />
        <hr>
        <ChangeCustomizationForm />
        <hr>
        <ChangeContactDataForm @updated:contact="patchUser" :user="store?.me" />
    </div>
</template>
<script>
    import {userStore, ResponseService, Logger, EventHandler} from "@development/cluster-center";
    import ChangeEmailForm from "@/components/Accounting/Profile/Forms/ChangeEmailForm.vue";
    import ChangeContactDataForm from "@/components/Accounting/Profile/Forms/ChangeContactDataForm.vue";
    import VerifyEmailForm from "@/components/Accounting/Profile/Forms/VerifyEmailForm.vue";
    import ChangeCustomizationForm from "@/components/Accounting/Profile/Forms/ChangeCustomizationForm.vue";

    export default {
        name: "PersonalData",
        components: {ChangeEmailForm, ChangeContactDataForm, VerifyEmailForm, ChangeCustomizationForm},
        data() {
            return {
                store: null
            };
        },
        mounted() {
            this.store = userStore();
        },
        methods: {
            patchUser(user) {
                try {
                    this.store.$patch(this.$cc.mergeDeep(this.store.me, user));
                    EventHandler.dispatch('app:updated:locale');
                } catch (e) {
                    ResponseService.danger(this.$i18n.t('errors.undefined', {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email}));
                    Logger.notify(e);
                }
            },
            updateMode(val) {
                this.store.darkMode = val;
            }
        },
        computed: {
            isUserEmailVerified() {
                if (this.store?.emailUpdateRequest) {
                    return false;
                }

                return this.store?.emailVerified || false;
            }
        }
    };
</script>
