<template>
    <MoleculeModal :primary-close="success" :close-text="success ? $t('helper.actions.close') : $t('helper.actions.abort')" v-model="display" size="lg">
        <template #modal-headline>
            {{ $t('demo.contact_form.title') }}
        </template>
        <div class="row w-100 d-flex justify-content-center" id="form-wrapper" :class="{'d-none': locale === 'en'}">
            <div id="hubspotFormDe" class="hubsport-form" v-once></div>
        </div>
        <div class="row w-100 d-flex justify-content-center" id="form-wrapper" :class="{'d-none': locale === 'de'}">
            <div id="hubspotFormEn" class="hubsport-form" v-once></div>
        </div>
        <template #alerts>
            <AtomAlert v-if="externalError" variant="danger" :dismissible="false" show>
                <p v-html="externalError"></p>
            </AtomAlert>
        </template>
        <template #actions>
            <AtomButton @click.prevent="sendForm" v-if="!success">
                {{ $t('demo.contact_form.send_button') }}
            </AtomButton>
        </template>
    </MoleculeModal>
</template>
<script setup>
    import {computed, inject, onMounted, ref, watch} from "vue";

    const emit = defineEmits(['close']);

    const i18n = window.Config.get('i18n');
    const $app = inject('$app');
    const $cc = inject('cc');

    const props = defineProps({
        show: Boolean
    });

    const display = computed({
        get: () => props.show,
        set: () => emit('close')
    });

    const locale = ref('de');

    onMounted(() => {
        const script = document.createElement("script");
        script.setAttribute("charset", "utf-8");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", "//js-eu1.hsforms.net/forms/embed/v2.js");
        document.body.appendChild(script);

        script.addEventListener("load", () => {
            if (window.hbspt) {
                createForm('#hubspotFormDe', $app.hubspot.demo.formIdDe);
                createForm('#hubspotFormEn', $app.hubspot.demo.formIdEn);
            }
        });

        observe();
        locale.value = i18n?.locale === 'en' ? 'en': 'de';

        window.addEventListener('app:updated:locale', () => {
            locale.value = i18n?.locale === 'en' ? 'en': 'de';
        });
    });

    const createForm = (target, formId) => {
        window.hbspt.forms.create({
            region: $app.hubspot.region,
            portalId: $app.hubspot.portalId,
            formId: formId,
            target: target
        });
    };

    const externalError = ref(null);
    const success = ref(false);
    const observe = () => {
        // Select the node that will be observed for mutations
        const targetNode = document.getElementById('form-wrapper');

        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: true, subtree: true };

        // Callback function to execute when mutations are observed
        const callback = mutationList => {
            for (const mutation of mutationList) {
                if (mutation.type === "childList") {
                    success.value = document.querySelector('.submitted-message');
                }
            }
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    };

    const sendForm = async () => {
        externalError.value = null;

        try {
            const selector = '#hubspotForm' + locale.value.ucfirst() + ' .hs-button';
            document.querySelector(selector).click();
        } catch {
            externalError.value = i18n.t('errors.undefined', {phone: $cc.support.phone_formatted, email: $cc.support.email });
        }
    };

    watch(() => props.show, () => {
        externalError.value = null;
    });
</script>
<style lang="scss">
    .hubsport-form {
        .hs-button {
            display: none !important;
        }

        .hs-input {
            width: 100% !important;
            border: 1px solid $input-border-color;
            border-radius: $input-border-radius !important;
            padding: $input-btn-padding-y $input-btn-padding-x;
            font-size: $input-btn-font-size !important;
            height: 40px;
            font-weight: $font-weight-base !important;
            line-height: $input-btn-line-height !important;
            background-image: none;

            &.hs-fieldtype-textarea {
                height: auto;
            }
        }

        fieldset {
            margin-bottom: 1.25rem !important;

            .hs_firstname {
                padding-right: calc($input-btn-padding-x * 2);
            }
        }

        .hs-error-msg {
            color: $white;
        }

        .form-columns-1 {
            max-width: 100% !important;
        }

        .form-columns-2 {
            max-width: 100% !important;
        }

        .field {
            position: relative;
        }

        ul.hs-error-msgs {
            position: absolute;
            background: $primary;
            color: $white;
            list-style: none;
            top: 0;
            left: 0;
            margin: 0;
            transform: translateY(-100%);
            padding: .35rem .5rem;
            font-size: .75rem;
            display: inline-flex;
            font-weight: bold;
            border-radius: .25rem;
            min-width: 140px;

            li {
                display: none;
                padding: 0;

                &:first-of-type {
                    display: block;
                }
            }

            &::after {
                content: "";
                position: absolute;
                top: calc(100% - 1px);
                left: .5rem;
                border-width: 5px;
                border-style: solid;
                border-color: $primary transparent transparent transparent;
            }
        }

        .hs_error_rollup {
            display: none;
        }

        .hs-form-required {
            color: $primary;
            transform: translate(2px, -2px);
            display: inline-block;
        }
    }
</style>