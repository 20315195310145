<template>
    <div class="partner-card mb-2 card">
        <div class="partner-card-header">
            <div class="col-12 mb-3">
                <h5 class="d-flex justify-content-between mb-0">
                    {{ $t('partner.payments.last_payout') }}
                    <span>{{ $n(getAmount(lastPayment.amountNet), 'currency') }}</span>
                </h5>
                <small>{{ $d(getDate(lastPayment), 'partner') }}</small>
            </div>
            <div class="col-12">
                <h5 class="d-flex justify-content-between mb-0">
                    {{ $t('partner.payments.past_payout') }}
                    <span>{{ $n(getAmount(provisionsPaid), 'currency') }}</span>
                </h5>
                <small>{{ currentYear }}</small>
            </div>
        </div>
        <MoleculeTable class="mb-0 partner-card-table table-layout-dynamic">
            <AtomTablePart type="body">
                <template v-for="i in 6" :key="`partner-payments-row-${i}`">
                    <AtomTableRow>
                        <template v-if="payments.length > 0 && payments[i-1]">
                            <AtomTableCell>
                                {{ $d(payments[i-1].invoicedAt.date, 'partner') }}
                            </AtomTableCell>
                            <AtomTableCell class="text-end">
                                {{ $n(payments[i-1].amountNet * -1, 'currency') }}
                            </AtomTableCell>
                        </template>
                        <template v-else>
                            <AtomTableCell :colspan="2"></AtomTableCell>
                        </template>
                    </AtomTableRow>
                </template>
            </AtomTablePart>
        </MoleculeTable>
    </div>
    <AtomButton :to="{name: 'partner-payouts', params: {partnerId: currentCustomer?.id, customerId: currentCustomer?.customer?.id}}">
        {{ $t('partner.payments.show_all') }}
    </AtomButton>
</template>
<script>
    export default {
        name: 'LastPayments',
        props: {
            payments: {
                type: Array,
                default: () => [],
            },
            lastPayment: {
                type: Object,
                default: () => {}
            },
            currentYear: {
                type: Number,
                default: new Date().getFullYear()
            },
            provisionsPaid: {
                type: Number,
                default: 0
            },
            currentCustomer: {
                type: Object,
                default: () => {}
            }
        },
        methods: {
            getAmount(amount) {
                if (!amount) {
                    return 0;
                }

                return amount * -1;
            },
            getDate(invoice) {
                if (!invoice) {
                    return new Date();
                }

                return new Date();
            }
        }
    };
</script>