let exceptions = ['dashboard'];

export default function isDemo({ nextRoute, to }) {

    if (
        window.Config.isDemo() &&
        !to.name.includes('managed-') &&
        !exceptions.includes(to.name)
    ) {
        return nextRoute({ name: 'managed-dashboard', params: {clusterId: 42, serverId: 1} });
    }

    return nextRoute();
}
