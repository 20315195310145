export default {
    de: {
        teaser_1: {
            headline: 'E-Commerce Stack im Application Center',
            description: 'Unser Application Center bietet Ihnen eine übersichtliche Verwaltungsoberfläche für Ihre Serverdienste und Sicherheitseinstellungen.<br><br>So können Sie hier beispielsweise innerhalb von Sekunden eine andere Version von einem Serverdienst installieren. Zusätzlich können Sie die Startparameter von Serverdiensten oder auch andere individuelle Konfigurationen einspielen.',
        },
        teaser_2: {
            headline: 'Monitoring',
            description: 'Der Zustand der eingesetzten Hardware sowie die Serverdienste werden in sehr kurzen Intervallen kontrolliert und wichtige Leistungsdaten erfasst. Diese Daten können Sie jederzeit im Monitoring abrufen und analysieren.<br><br>Doch natürlich haben auch unsere Linux-Administratoren alle wichtigen Schwellwerte im Blick und greifen ein, sobald ein Serverdienst nicht mehr läuft oder die Hardware für die Bedürfnisse Ihres Onlineshops nicht mehr ausreichend ist.',
        },
        start_button: 'Einführung starten',
        start_tour: 'Tour starten',
        contact_form: {
            title: 'Haben wir Interesse geweckt?',
            email: 'Email:',
            phone: 'Telefon:',
            description: 'Dann kontaktieren Sie unsere technische Beratung. Wir stehen Ihnen montags bis freitags zwischen 09:00 und 18:00 Uhr gerne für eine Beratung zur Verfügung.',
            contact_label: 'Ansprechpartner',
            company_label: 'Unternehmen',
            email_label: 'E-Mail',
            phone_label: 'Telefon',
            message_label: 'Ihre Nachricht',
            data_secure_text: 'Die von Ihnen mitgeteilten persönlichen Daten werden ausschließlich zur Beantwortung Ihrer Anfrage gespeichert. Weitere Informationen befinden sich in unserer <a href="https://maxcluster.de/datenschutz" target="_blank">Datenschutzerklärung</a>.',
            send_button: 'Kontakt aufnehmen',
            success_msg: 'Ihre Nachricht wurde erfolgreich zugestellt.'
        },
        disabled_demo: 'Diese Funktion ist in der Demo nicht verfügbar, ist aber in jedem Service Level enthalten.'
    },
    en: {
        teaser_1: {
            headline: 'E-Commerce Stack at the Application Center',
            description: 'Our Application Center offers you a clear administration interface for your server services and security settings.<br><br>For example, you can install a different version of a server service here within seconds. You can also import the start parameters of server services or other individual configurations.',
        },
        teaser_2: {
            headline: 'Monitoring',
            description: 'The status of the hardware used and the server services are checked at very short intervals and important performance data is recorded. You can call up and analyze this data at any time in the monitoring.<br><br>Of course, our Linux administrators also keep an eye on all important threshold values and intervene as soon as a server service stops running or the hardware is no longer sufficient for the needs of your online store.',
        },
        start_button: 'Start introduction',
        start_tour: 'Start tour',
        contact_form: {
            title: 'Have we aroused your interest?',
            email: 'Email:',
            phone: 'Phone:',
            description: 'Then contact our technical advice team. We are available for advice from Monday to Friday between 09:00 and 18:00',
            contact_label: 'Contact Person',
            company_label: 'Company',
            email_label: 'Email',
            phone_label: 'Phone',
            message_label: 'Your Message',
            data_secure_text: 'The personal data you provide will be stored exclusively for the purpose of responding to your inquiry. Further information can be found in our <a href="https://maxcluster.de/en/privacy-policy" target="_blank">Privacy Policy</a>.',
            send_button: 'Submit',
            success_msg: 'Your message has been successfully delivered.',
        },
        disabled_demo: 'This function is not available in the demo, but is included in every service level.'
    }
};
