<template>
    <PartnerHeaderLine />
    <MoleculeLoader size="lg" v-if="pageIsLoading" />
    <template v-else>
        <div class="row mb-4">
            <div class="col"></div>
            <div class="col d-flex justify-content-end">
                <AtomDropdown style-variant="secondary" class="partner-manager-info">
                    <template #button-content>
                        <div class="row">
                            <div class="col-auto">
                                <AtomAvatar>
                                    <AtomImage :size="55" :src="require('@/assets/images/partner_portal/Jan_klein.jpg')" />
                                </AtomAvatar>
                            </div>
                            <div class="col-auto text-start ps-0 pe-4 pt-2 partner-manager-name">
                                {{ $t('partner.partner_manager') }}<br>
                                <strong>Jan Ahlers</strong>
                            </div>
                        </div>
                    </template>
                    <AtomDropdownItem href="tel:+495251414139">
                        <AtomIcon name="phone-handset-line"/>&nbsp;&nbsp;+49 5251 4141 39 <span class="info">| 09:00 - 18:00 Uhr</span>
                    </AtomDropdownItem>
                    <AtomDropdownItem href="mailto:partner@maxcluster.de">
                        <AtomIcon name="envelope-line"/>&nbsp;&nbsp;partner@maxcluster.de
                    </AtomDropdownItem>
                </AtomDropdown>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-lg-8">
                <div class="card py-3 h-100 mb-0">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 d-flex">
                                <h4 class="me-3">{{ $t('partner.commission_overview') }}</h4>
                                <AtomSelect :allow-empty="false" id="year-selection" :options="selectableYears" :placeholder="currentYear.toString()" v-model="currentYear" />
                            </div>
                            <div class="col-lg-6 legend-container">
                                <div class="legend-wrapper">
                                    <div>{{ $t('partner.already_payed_commission') }}</div>
                                    <div>{{ $t('partner.expected_commission') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MoleculeChart chart-type="column" style="height:400px;" :series="series" :data="seriesData" category="month" v-if="provisionChart" />
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card">
                    <div class="gauge-wrapper" v-if="gaugeChart">
                        <h4 class="text-center">
                            {{ $t('partner.current_level') }}<span id="partner_level">{{ currentLevel.levelName }}</span>
                            <AtomIcon name="info-line" class="cursor-pointer hover-primary" @click.prevent="showInfoModal = true" />
                        </h4>
                        <MoleculeChart chart-type="gauge" style="height:200px;" :series="[]" :data="gaugeSeriesData" category="category" />
                        <span class="current-level">
                            {{ currentLevel.levelName }}
                            <span>
                                <template v-if="currentLevel?.currentAmount && currentLevel?.currentAmount > 0">
                                    {{ $t('partner.from_euro', { euro: $n(currentLevel.currentAmount, 'currency') }) }}
                                </template>
                                <template v-else>
                                    {{ $t('partner.from_euro', { euro: $n(0, 'currency') }) }}
                                </template>
                            </span>
                        </span>
                        <span class="next-level">
                            {{ currentLevel?.nextLevel.levelName || '-' }}
                            <span>
                                <template v-if="missingEuro > 0">
                                    {{ $t('partner.from_euro', { euro: $n(currentLevel?.nextLevel.limit || 0, 'currency') }) }}
                                </template>
                                <template v-else>-</template>
                            </span>
                        </span>
                        <span class="percentage">
                            {{ $n(currentLevel.reachedPercentage / 100, 'percent') }}
                            <span class="missing">
                                <template v-if="missingEuro > 0">
                                    {{ $t('partner.missing', {euro: $n(missingEuro, 'currency')}) }}
                                </template>
                                <template v-else>
                                    {{ $t('partner.reached_limit') }}
                                </template>
                            </span>
                        </span>
                    </div>
                    <MoleculeTable class="m-0 p-0 partner-card-table">
                        <AtomTablePart type="body">
                            <AtomTableRow v-for="level in currentLevels" :key="`partner-level-${level.name}`">
                                <AtomTableCell>
                                    {{ $t(`partner.levels.${level.name}`) }} ({{ $n(level.provision_percentage / 100, 'percent', {minimumFractionDigits: 1}) }})
                                </AtomTableCell>
                                <AtomTableCell class="text-end">
                                    {{ $n(level.limit, 'currency') }}
                                </AtomTableCell>
                            </AtomTableRow>
                        </AtomTablePart>
                    </MoleculeTable>
                </div>
            </div>
        </div>
        <div class="row fix-table-height">
            <div :class="{'col-md-6': !fullwidth, 'col-md-6 col-lg-6 col-xl-4': fullwidth}">
                <LastPayments :payments="provisions" :lastPayment="lastPayment" :current-year="parseInt(currentYear)" :current-customer="currentCustomer" :provisions-paid="alreadyPayed" />
            </div>
            <div :class="{'col-md-6': !fullwidth, 'col-md-6 col-lg-6 col-xl-4': fullwidth}">
                <NewestContracts :acquisitions="currentCustomer?.acquisitions" :current-year="parseInt(currentYear)" :current-customer="currentCustomer" />
            </div>
            <div :class="{'col-12 mt-4': !fullwidth, 'col-xl-4 mt-xl-0 mt-4': fullwidth}">
                <SalesMaterial :sales-material="salesMaterial" :current-year="parseInt(currentYear)" :current-customer="currentCustomer" />
            </div>
        </div>
        <MoleculeModal :close-text="$t('helper.actions.close')" @close="showInfoModal = false" v-model="showInfoModal" :show-modal="showInfoModal" size="xl">
            <template #modal-headline>
                <h3 class="relative-1">{{ $t('partner.commission_levels.title') }}</h3>
            </template>
            <MoleculeTable>
                <AtomTablePart type="header">
                    <AtomTableRow>
                        <AtomTableCell header>{{ $t('partner.commission_levels.area') }}</AtomTableCell>
                        <AtomTableCell header>{{ $t('partner.commission_levels.performance') }}</AtomTableCell>
                        <AtomTableCell class="text-center" header>{{ $t('partner.commission_levels.bronze', {amount: $n(0, 'currency')}) }}</AtomTableCell>
                        <AtomTableCell class="text-center" header>{{ $t('partner.commission_levels.silver', {amount: $n(5000, 'currency')}) }}</AtomTableCell>
                        <AtomTableCell class="text-center" header>{{ $t('partner.commission_levels.gold', {amount: $n(7500, 'currency')}) }}</AtomTableCell>
                        <AtomTableCell class="text-center" header>{{ $t('partner.commission_levels.platinum', {amount: $n(20000, 'currency')}) }}</AtomTableCell>
                    </AtomTableRow>
                </AtomTablePart>
                <AtomTablePart type="body">
                    <AtomTableRow>
                        <AtomTableCell>{{ $t('partner.commission_levels.commission') }}</AtomTableCell>
                        <AtomTableCell></AtomTableCell>
                        <AtomTableCell class="text-center">{{ $n(.05, 'percent') }}</AtomTableCell>
                        <AtomTableCell class="text-center">{{ $n(.075, 'percent') }}</AtomTableCell>
                        <AtomTableCell class="text-center">{{ $n(.1, 'percent') }}</AtomTableCell>
                        <AtomTableCell class="text-center">{{ $n(.125, 'percent') }}</AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell>{{ $t('partner.commission_levels.support') }}</AtomTableCell>
                        <AtomTableCell>24/7 {{ $t('partner.commission_levels.support') }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell></AtomTableCell>
                        <AtomTableCell>{{ $t('partner.commission_levels.agency_cluster', {amount: $n(500, 'currency')}) }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell></AtomTableCell>
                        <AtomTableCell>{{ $t('partner.commission_levels.partner_manager_agency') }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell></AtomTableCell>
                        <AtomTableCell>{{ $t('partner.commission_levels.sales_material') }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell>{{ $t('partner.commission_levels.advice') }}</AtomTableCell>
                        <AtomTableCell>{{ $t('partner.commission_levels.accompaniment_to_pitch') }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell>{{ $t('partner.commission_levels.marketing') }}</AtomTableCell>
                        <AtomTableCell>{{ $t('partner.commission_levels.logo_mention') }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell></AtomTableCell>
                        <AtomTableCell>{{ $t('partner.commission_levels.customer_citation') }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell></AtomTableCell>
                        <AtomTableCell>{{ $t('partner.commission_levels.testimonial') }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell></AtomTableCell>
                        <AtomTableCell>{{ $t('partner.commission_levels.case_study') }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell></AtomTableCell>
                        <AtomTableCell>{{ $t('partner.commission_levels.marketing_newsletter') }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell></AtomTableCell>
                        <AtomTableCell>{{ $t('partner.commission_levels.video') }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                    <AtomTableRow>
                        <AtomTableCell>{{ $t('partner.commission_levels.others') }}</AtomTableCell>
                        <AtomTableCell>{{ $t('partner.commission_levels.dedicated_project_manager') }}</AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="close-line" class="color-danger" /></AtomTableCell>
                        <AtomTableCell class="text-center"><AtomIcon name="success-line" class="color-success" /></AtomTableCell>
                    </AtomTableRow>
                </AtomTablePart>
            </MoleculeTable>
        </MoleculeModal>
    </template>
</template>
<script setup>
    import NewestContracts from "./NewestContracts.vue";
    import SalesMaterial from "./SalesMaterial.vue";
    import LastPayments from "./LastPayments.vue";
    import PartnerHeaderLine from "@/components/Accounting/Partner/PartnerHeaderLine.vue";
    import {getMonthShortNameList} from '@/utils/helpers';
    import {useUpdatePageTitle} from "@/composables/page";
    import {
        getLevels,
        userPartnerInformation,
        getSalesMaterial,
        getExpectedRevenue,
        getProvisions,
        prepareCurrentLevel,
        prepareData
    } from "@/composables/partner";
    import {computed, onMounted, ref, watch} from "vue";
    import {useRoute, useRouter} from "vue-router";
    import {Logger, ResponseService, userStore} from "@development/cluster-center";

    const emit = defineEmits(['route:params:changed']);
    const route = useRoute();
    const router = useRouter();
    const i18n = window.Config.get('i18n');
    const support = window.Config.get('support');

    const showInfoModal = ref(false);

    const {
        currentCustomer,
        currentLevels,
        levels,
        alreadyPayed,
        selectableYears,
        monthlyData,
        provisions,
        getPartnerCustomerData,
        getCurrentPartnerShip
    } = userPartnerInformation();

    const currentYear = ref(route.params?.year || new Date().getFullYear());
    const salesMaterial = ref([]);
    const series = ref([]);
    const seriesData = ref([]);

    onMounted(async () => {
        if (route.params.year) {
            currentYear.value = parseInt(route.params.year);
        }

        levels.value = await getLevels();
        await initialize();
    });

    const expectedRevenue = ref(0);
    const lastPayment = ref({});
    const provisionChart = ref(false);
    const pageIsLoading = ref(true);

    const initialize = async () => {
        let hasError = false;
        pageIsLoading.value = true;

        try {
            await getPartnerCustomerData().then(response => {
                if (Array.isArray(response)) {
                    hasError = true;
                    return ResponseService.danger(i18n.t(response[1], {phone: support.phone_formatted, email: support.email }));
                }
            });

            if (hasError) {
                return;
            }

            salesMaterial.value = await getSalesMaterial().then(({data, error}) => {
                hasError = error;
                return data;
            });

            if (hasError) {
                return;
            }

            expectedRevenue.value = await getExpectedRevenue(currentCustomer.value?.id || 0).then(({data, error}) => {
                hasError = error;
                return data;
            });

            const customerId = currentCustomer.value?.customer?.id || 0;

            if (hasError) {
                return;
            }

            monthlyData.value = await getProvisions(
                customerId,
                {
                    customer_id: customerId,
                    type: 'provision'
                }
            ).then(({data, error}) => {
                hasError = error;
                return data;
            });

            if (hasError) {
                return;
            }

            let year = currentYear.value || new Date().getFullYear();

            provisions.value = await getProvisions(
                customerId,
                {
                    customer_id: customerId,
                    type: 'provision',
                    not_invoice_items: 1,
                    from_date: `${year}-01-01`,
                    to_date: `${year}-12-31`,
                    sort_by: 'invoiced_at.desc',
                    status: 'cleared',
                }
            ).then(({data, error}) => {
                hasError = error;
                return data;
            });

            if (hasError) {
                return;
            }

            lastPayment.value = await getProvisions(
                customerId,
                {
                    customer_id: customerId,
                    type: 'provision',
                    not_invoice_items: 1,
                    sort_by: 'invoiced_at.desc',
                    status: 'cleared',
                    per_page: 1
                }
            ).then(({data, error}) => {
                hasError = error;
                return !data ? {} : data[0] || {};
            });

            if (hasError) {
                return;
            }

            prepareGauge();
            prepareSeries();
            provisionChart.value = true;
        } catch (e) {
            Logger.log(e, {
                'custom-error-information': {
                    component: 'PartnerOverview',
                    function: 'initialize'
                }
            });
        } finally {
            pageIsLoading.value = false;
        }
    };

    const gaugeChart = ref(false);
    const missingEuro = ref(0);
    const gaugeSeriesData = ref([]);
    const currentLevel = ref({});

    const prepareGauge = () => {
        let currentPartnership = getCurrentPartnerShip();
        currentLevel.value = prepareCurrentLevel(currentPartnership, expectedRevenue.value);

        if (window.isEmpty(currentLevel.value)) {
            return;
        }

        const currentAmount = currentLevel.value?.currentAmount || 0;

        missingEuro.value = (currentLevel.value.nextLevel.limit || 0) - currentAmount;

        gaugeSeriesData.value = [{
            start: 0,
            end: currentLevel.value?.reachedPercentage > 100 ? 100 : currentLevel.value.reachedPercentage,
            color: '#446CB3'
        }, {
            start: currentLevel.value?.reachedPercentage > 100 ? 100 : currentLevel.value.reachedPercentage,
            end: 100,
            color: '#cdcccc'
        }];

        gaugeChart.value = true;
    };

    const prepareSeries = () => {
        let monthShortName = getMonthShortNameList();

        // reset data
        series.value = [];
        seriesData.value = [];

        let tmpSeries = [];
        let tmpSeriesData = [];

        let cleared = prepareData(monthlyData.value, 'cleared', currentYear.value);
        let expected = prepareData(monthlyData.value, 'expected', currentYear.value);
        let open = prepareData(monthlyData.value, 'open', currentYear.value);

        for (let i = 0; i < 12; i++) {
            let value = 0;
            let expectedValue = 0;
            let expectedSeries = false;
            let openValue = open[i].value;

            if (openValue < 0) {
                openValue = openValue * -1;
            }

            if (cleared[i] && Object.prototype.hasOwnProperty.call(cleared[i], 'value')) {
                if (cleared[i].value < 0) {
                    value = cleared[i].value * -1;
                } else {
                    value = cleared[i].value;
                }
            }

            if (expected[i] && Object.prototype.hasOwnProperty.call(expected[i], 'value')) {
                if (expected[i].value < 0) {
                    expectedValue = expected[i].value * -1;
                } else {
                    expectedValue = expected[i].value;
                }
            }

            if (open[i] && Object.prototype.hasOwnProperty.call(open[i], 'value') && openValue > 0) {
                expectedValue = openValue;
            }

            if (value === 0 && expectedValue > 0) {
                value = expectedValue;
                expectedSeries = true;
            }

            tmpSeriesData.push({
                month: monthShortName[i],
                value: value,
                formatted: i18n.n(value, 'currency'),
            });

            tmpSeries.push({
                name: 'Series ' + i,
                options: {
                    valueYField: 'value',
                    sequencedInterpolation: false,
                    categoryXField: 'month',
                    fill: expectedSeries ? "#cdcccc" : "#446CB3",
                    stroke: expectedSeries ? "#cdcccc" : "#446CB3",
                    clustered: false,
                }
            });
        }

        series.value = tmpSeries;
        seriesData.value = tmpSeriesData;
    };

    watch(() => route.params.year, year => {
        currentYear.value = parseInt(year);
        provisionChart.value = false;
        initialize();
    });

    watch(() => route.params.partnerId, () => {
        provisionChart.value = false;
        initialize();
    });

    watch(() => currentYear.value, year => {
        if (!currentCustomer.value) {
            return;
        }

        let params = {
            partnerId: currentCustomer.value.id,
            customerId: currentCustomer.value.customer.id,
            year: parseInt(year)
        };

        if (params.partnerId === 'NaN' || Number.isNaN(parseInt(params.partnerId))) {
            delete params.partnerId;
        }

        if (params.customerId === 'NaN' || Number.isNaN(parseInt(params.customerId))) {
            delete params.customerId;
        }

        if (params.year === 'NaN' || Number.isNaN(parseInt(params.year))) {
            delete params.year;
        }

        router.push({
            name: 'partner-overview',
            params: params
        });

        useUpdatePageTitle(route.meta?.title || '');

        emit('route:params:changed', params);
    });

    const user = userStore();
    const fullwidth = computed(() => user.fullwidth);
</script>
<style lang="scss" scoped>
    .legend-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .legend-wrapper {
        display: flex;
        font-size: .85rem;

        div {
            position: relative;
            padding-left: 15px;

            &::before {
                position: absolute;
                content: " ";
                height: 20px;
                width: 20px;
                border-radius: 2px;
                box-shadow: 0 1px 1px rgb(0 0 0 / 40%);
                left: -12.5px;
                background: $gray-500;
            }

            &:first-of-type {
                margin-right: 2rem;

                &::before {
                    background: $info;
                }
            }
        }
    }

    .gauge-wrapper {
        position : relative;
        padding: 1rem 0 2.5rem;

        .current-level {
            position: absolute;
            left: .5rem;
            bottom: 1rem;
        }

        .next-level {
            position: absolute;
            right: .5rem;
            bottom: 1rem;
            text-align: right;
        }

        span > span {
            display: block;
            font-size: .75rem;
            color: $info;
        }

        .percentage {
            position: absolute;
            left: 50%;
            top: 60%;
            text-align: center;
            transform: translate(-50%, -50%);
            font-size: 1.75rem;
            font-weight: bold;

            span {
                color: $gray-600;
            }
        }
    }
</style>
<style lang="scss">
    .partner-card {
        .partner-card-header {
            height: 160px;
            padding: .75rem;
            border-bottom: 0;

            p {
                font-size: .9rem;
                font-weight: 300;
            }
        }

        tr {
            height: 65px;
        }
    }

    .partner-manager-info {
        margin-top: .25rem;
        box-sizing: border-box;
        border: 1px solid $white;
        padding: .3rem 0;

        .atom-avatar {
            position: relative;
            z-index: 99;
        }

        &.atom-dropdown-is-open {
            border-color: $dropdown-border-color;
            border-radius: $dropdown-active-radius;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .dropdown-menu.show {
            right: 0 !important;
            left: auto !important;
            z-index: 991;
            transform: translate(2px, 54px) !important;
            border-top-right-radius: 0 !important;

            li {
                white-space: nowrap;

                a {
                    font-size: .9rem;
                    color: $gray-900;
                    text-decoration: none;
                    font-weight: bold;

                    span.info {
                        font-weight: normal;
                    }
                }

                &:hover a {
                    color: $white;
                }
            }
        }

        .dropdown-toggle {
            border: 0;

            &.show {
                border-bottom: 0;
                overflow: visible;
                position: relative;
                z-index: 1001;

                &::before {
                    width: 100%;
                    background: $white;
                    height: 1px;
                    bottom: -9px;
                    left: 0;
                    position: absolute;
                    content: '';
                    z-index: 999;
                }
            }
        }
    }

    .fix-table-height table tbody tr {
        height: 84px;
    }

    .partner-card-table {
        border-right-width: 0;
        border-bottom-width: 0;
        border-left-width: 0;

        td {
            border-bottom: 0;
        }
    }

    .partner-manager-name {
        line-height: 1.25rem;
    }
</style>
