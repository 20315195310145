import { ref, onMounted, onUnmounted } from 'vue';

export function useColorScheme() {
    const colorScheme = ref(getColorScheme());

    function getColorScheme() {
        if (window.matchMedia && !window.Config.isDemo()) {
            return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        }

        return 'light';
    }

    function handleColorSchemeChange(e) {
        colorScheme.value = e.matches ? 'dark' : 'light';
    }

    onMounted(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        mediaQuery.addEventListener('change', handleColorSchemeChange);

        colorScheme.value = getColorScheme();
    });

    onUnmounted(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        mediaQuery.removeEventListener('change', handleColorSchemeChange);
    });

    return { colorScheme };
}
