export default {
    de: {
        no_insider: 'Sie sind nicht für Beta-Phase registriert.',
        coming_soon: 'Dieses Feature ist noch nicht verfügbar.',
        please_reload: 'Es gab Änderungen an der Anwendung. Bitte laden Sie die Seite neu, um fortzufahren.',
        navigation: {
            clusters: 'Cluster-Übersicht',
            contracts: 'Verträge',
            invoices: 'Rechnungen',
            orders: 'Bestellungen',
            partners: {
                title: 'Partner',
                overview: 'Übersicht',
                payouts: 'Auszahlungen',
                sales_material: 'Vertriebsmaterial',
                customers: 'Kunden'
            },
            managed: {
                dashboard: 'Dashboard',
                favourites: 'Favoriten',
                monitoring: 'Monitoring',
                webserver: 'Webserver',
                database_and_search: 'Datenbank & Suche',
                cache_and_queue: 'Cache & Queue',
                security_and_backups: 'Security & Backups',
                node: 'Node.js',
                shopperformance: 'ShopPerformance',
                email: 'E-Mail',
                dns: 'DNS',
                cluster_control: 'Cluster Control',
                package_updates: 'Package Updates',
                mail_server: 'Mail-Server',
                postfix: 'Postfix / Sendmail'
            },
            profile: {
                my_data: 'Meine Daten',
                logout: 'Logout'
            }
        },
        page_title: {
            invoice: 'Rechnungen | Rechnung | Rechnungen',
        },
        switch_design: {
            label: 'Zur alten Oberfläche',
            title: 'Zur alten Oberfläche wechseln',
            description: 'Sie möchten zur alten Oberfläche wechseln? Bitte melden Sie sich erneut an, um diese nutzen zu können. Wir würden uns freuen, wenn Sie uns ein Feedback geben, warum Sie diese Oberfläche bevorzugen. Vielen Dank.',
            switch: 'Jetzt wechseln'
        },
        invitation_description: 'Sie wurden dazu eingeladen den {type} {id} zu verwalten.',
        invite_customer: 'Kunden',
        invite_cluster: 'Cluster',
        invitation_accept_success: 'Die Einladung wurde erfolgreich angenommen.',
        invitation_decline_success: 'Die Einladung wurde erfolgreich abgelehnt.',
        footer: {
            imprint: 'Impressum',
            advice: 'Beratung',
            data_protection: 'Datenschutz',
            tos: 'AGB',
            service_level_agreement: 'SLA',
            guidelines: 'Richtlinien',
        },
        event: {
            title: 'Kerzenschein und Plätzchenduft - Weihnachten liegt in der Luft',
            description: 'Und damit Sie entspannt die Feiertage genießen können, ist unser Service-Team auch über die Feiertage und die Zeit zwischen den Jahren für Sie im Einsatz. Das gesamte maxcluster-Team wünscht Ihnen und Ihren Liebsten eine besinnliche Weihnachtszeit, einen guten Rutsch ins neue Jahr und für 2024 viel Erfolg, Gesundheit und Glück!',
            black_friday: {
                title: 'Black Friday 2023',
                description: 'Am <strong>24. November</strong> ist es erneut soweit: Der <strong>Black Friday</strong> bringt die Onlinewelt in einen Ausnahmezustand!<br><br>Auch bei der <strong>Durchführung von Optimierungen unterstützen wir</strong> Sie natürlich gerne!<br><br>Um perfekt gerüstet zu sein, füllen Sie bitte das Formular aus: <a class="atom-text-link" href="https://share-eu1.hsforms.com/12qjypHBcTtqxHxx3U7LSbgf2v0q" target="_blank" rel="noreferrer">Zum Formular</a><br><br>Übrigens: Unter allen Teilnehmern am Black Friday, die sich bis zum <strong>06.11.2023</strong> bei uns anmelden, <strong>verlosen wir wieder dreimal die notwendigen zusätzlichen Ressourcen</strong>.<br><br>(Die Verlosung erfolgt automatisch über ein Online-Los-Tool. Die Gewinner werden von uns per E-Mail benachrichtigt. Der Rechtsweg ist ausgeschlossen.)'
            },
            new_app_teaser: {
                title: 'Neues Application Center ist jetzt Standard',
                description: 'Das neue Design unseres Application Centers ist jetzt als Standard-Version gesetzt. Mit dabei auch die beiden neuen Funktionen, mit denen Sie einfach zwischen Clustern wechseln und Menüpunkte als Favoriten setzen können. Schicken Sie uns gerne auch weiterhin Ihr Feedback zum neuen Design per E-Mail an <a href="mailto:{mail}" class="atom-text-link">{mail}</a>. Den Vorgänger können Sie weiterhin nutzen, indem Sie aktiv auf die alte Version umstellen.'
            },
            service_provider_award: {
                title: 'Wir brauchen deine Stimme!',
                description: 'Wir sind begeistert, dass wir bei <strong>Vogel IT</strong> für den <strong>Award 2024</strong> als <strong>bester Application und Managed Hoster nominiert</strong> wurden! Deine Stimme ist bis zum <strong>05.04.2024</strong> entscheidend, um uns zu helfen, den Award zu gewinnen. Bitte nimm dir einen Moment Zeit, um uns deine Stimme zu schenken: <a target="_blank" href="https://www.sp-summit.de/award" class="atom-text-link" rel="noopener nofollow">Umfrage</a>.<br><br>Als Dank verlosen wir unter allen Teilnehmern Gutscheine von <a href="https://www.wunschgutschein.de/pages/wertgutschein" rel="noopener nofollow" target="_blank" class="atom-text-link">Wertgutschein.de</a>. Sende uns einfach einen Screenshot deines Votings an <a href="mailto:{mail}" class="atom-text-link">{mail}</a>. Deine Unterstützung bedeutet uns viel!'
            },
            knowledge_base: {
                title: 'Entdecke unsere brandneue Knowledge Base!',
                description: '<p>Tauche ein in eine Welt voller Wissen rund um Hosting, unser Managed Center und verschiedene Anwendungen! Unsere neue, stetig wachsende Knowledge Base ist dein Tor zu einer Fülle von Informationen, Tipps und Tricks.</p><ul class="list-style-none"><li><span class="atom-icon icon-check-line line"></span>Finde Antworten auf deine Fragen.</li><li><span class="atom-icon icon-check-line line"></span>Entdecke neue Lösungsansätze.</li><li><span class="atom-icon icon-check-line line"></span>Vertiefe dein Wissen in verschiedenen Bereichen.</li></ul><p>Zu erreichen ist die Wissensdatenbank über <a href="https://knowledge.maxcluster.de" target="_blank" title="maxcluster GmbH - Knowledge Base" rel="nofollow" class="atom-text-link">https://knowledge.maxcluster.de</a> und führt schon mit wenigen Stichworten zu einer Vielzahl hilfreicher Artikel. Dein Feedback kannst du gerne per <a href="mailto:{mail}" class="atom-text-link">E-Mail</a> mit uns teilen.</p>'
            },
            dark_mode: {
                title: 'Neue Darstellungsoptionen',
                description: '<p>Unser brandneuer Dark Mode ist da! Ab sofort könnt ihr unser Application Center in einem coolen Dark Mode erleben, der unserer Anwendung einen neuen, stylischen Look verleiht, aber vor allem eure Augen schont.</p><p>Außerdem könnt ihr unser Application Center nun über die ganze Breite eures Screens erleben.\nMit nur zwei Klicks könnt ihr zwischen den Modi wechseln. Über euer Kürzel könnt ihr ganz einfach den Dark Mode und die ganze Breite aus- und abwählen. Probiert es direkt aus.</p><p>Und wie immer gilt: Wir freuen uns über jedes Feedback. Schick uns deine Meinung gerne per <a href="mailto:{mail}" class="atom-text-link">E-Mail</a>.</p>'
            },
            new_price: {
                title: 'Neue Preise ab 1.9.2024',
                description: '<p>Ab dem 1. September 2024 werden wir unsere SLA-Pakete anpassen und zusätzliche Leistungen integrieren, um Ihnen noch mehr Mehrwerte bieten zu können.</p><ul><li>Managed Basic: Erhöhung der monatlichen Gebühr von 40€ auf 60€</li><li>Managed Business: Erhöhung der monatlichen Gebühr von 120€ auf 150€</li><li>Managed Enterprise: keine Erhöhung</li></ul><p>Eine genaue Leistungsbeschreibung - auch unseres neuen Managed Dev dieses - sowie weitere Informationen finden Sie <a href="https://maxcluster.de/faq-neue-preise?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-8rg0XP6Q1K7ad2ujkr1aN26guUO2M81PE_sGfLE38HkNIf2VGS-uLJrcW3ZMJhSwRD5npD" target="_blank" class="atom-text-link">hier</a>.</p>'
            },
            knowledge_base_new:{
                title: 'Entdecke unsere brandneue Knowledge Base!',
                description: 'Tauche ein in eine Welt voller Wissen rund um Hosting, unser Managed Center und verschiedene Anwendungen! Unsere neue, stetig wachsende Knowledge Base ist dein Tor zu einer Fülle von Informationen, Tipps und Tricks.',
                features: ['Finde Antworten auf deine Fragen.', 'Entdecke neue Lösungsansätze.', 'Vertiefe dein Wissen in verschiedenen Bereichen.'],
                additionalText: 'Zu erreichen ist die Wissensdatenbank über <a href="https://knowledge.maxcluster.de" target="__blank">https://knowledge.maxcluster.de</a> und führt schon mit wenigen Stichworten zu einer Vielzahl hilfreicher Artikel. Dein Feedback kannst du gerne per <a href="mailto:{mail}">E-Mail</a>  mit uns teilen.'
            }
        },
        phone: 'Telefon',
        fax: 'Fax',
        mobile: 'Mobil',
        application_center_title: 'Application Center',
        login_sub_title: 'Login',
        forgot_password_sub_title: 'Passwort vergessen',
        two_factor_sub_title: 'Zwei-Faktor-Authentifizierung',
        reset_password_sub_title: 'Passwort zurücksetzen',
        price_changes: '<strong>Bitte beachten Sie:</strong> Ab dem 1.9.2024 gelten neue Preise für unsere SLAs Managed Basic und Managed Business. Die Preisänderungen entnehmen Sie bitte <a href="https://maxcluster.de/faq-neue-preise" class="atom-text-link" target="_blank">hier</a>.',
    },
    en: {
        navigation: {
            clusters: 'Cluster overview',
            contracts: 'Contracts',
            invoices: 'Invoices',
            orders: 'Orders',
            partners: {
                title: 'Partner',
                overview: 'Overview',
                payouts: 'Payouts',
                sales_material: 'Sales material',
                customers: 'Customers'
            },
            managed: {
                dashboard: 'Dashboard',
                favourites: 'Favourites',
                monitoring: 'Monitoring',
                webserver: 'Webserver',
                database_and_search: 'Database & search',
                cache_and_queue: 'Cache & Queue',
                security_and_backups: 'Security & backups',
                node: 'Node.js',
                shopperformance: 'ShopPerformance',
                email: 'Email',
                dns: 'DNS',
                cluster_control: 'Cluster Control',
                package_updates: 'Package updates',
                mail_server: 'Mail-Server',
                postfix: 'Postfix / Sendmail'
            },
            profile: {
                my_data: 'My data',
                logout: 'Logout'
            }
        },
        page_title: {
            invoice: 'Invoice | Invoices',
        },
        invitation_description: 'You have been invited to manage the {type} {id}.',
        invite_customer: 'customer',
        invite_cluster: 'cluster',
        invitation_accept_success: 'The invitation was successfully accepted.',
        invitation_decline_success: 'The invitation was successfully declined.',
        switch_design: {
            label: 'Switch to old',
            title: 'Switch to old design',
            description: 'Would you like to switch to the old interface? Please log in again to be able to use it. We would be pleased if you could give us feedback on why you prefer this interface. Thank you very much.',
            switch: 'Switch now'
        },
        footer: {
            imprint: 'Imprint',
            advice: 'Consulting',
            data_protection: 'Data protection',
            tos: 'GTC',
            service_level_agreement: 'SLA',
            guidelines: 'Guidelines',
        },
        no_insider: 'You are not registered for the beta phase.',
        coming_soon: 'This feature is not yet available.',
        please_reload: 'There have been changes to the application. Please reload the page to continue.',
        event: {
            title: 'Its beginning to look a lot like Christmas',
            description: 'And to ensure that you can relax during the festive season, our service team will also be on duty throughout the holidays and between the years. The maxcluster team wishes you and your loved ones a Merry Christmas, a Happy New Year and lots of success, health and happiness for 2024!',
            black_friday: {
                title: 'Black Friday 2023',
                description: 'On <strong>November 24th</strong> it will happen again: Black Friday will bring the online world into a state of emergency!<br><br>Of course, we are also happy to support you in the <strong>implementation of optimizations</strong>!<br><br>To be perfectly prepared, please fill out the form: <a class="atom-text-link" href="https://share-eu1.hsforms.com/12qjypHBcTtqxHxx3U7LSbgf2v0q" target="_blank" rel="noreferrer">To the form</a><br><br>By the way: Among all participants in Black Friday who register with us by <strong>06.11.2023</strong>, we will again raffle off three times the necessary additional resources.<br><br>(The raffle will be done automatically via an online lottery tool. The winners will be notified by us by e-mail. The legal process is excluded.)'
            },
            new_app_teaser: {
                title: 'New Application Center is now set as default',
                description: 'The new design of our Application Center is now set as the default version. This also includes the two new functions that allow you to easily switch between clusters and set menu items as favorites. If you have any feedback on the new design, please send us an e-mail to <a href="mailto:{mail}" class="atom-text-link">{mail}</a>. You can continue to use the previous version by actively switching to the old version.'
            },
            service_provider_award: {
                title: 'We need your vote!',
                description: 'We\'re excited to announce that we\'ve been nominated for the <strong>2024 Vogel IT Award</strong> as the <strong>best Application and Managed Hoster!</strong> Your vote is crucial until <strong>April 5, 2024,</strong> to help us win the award. Please take a moment to lend us your voice by participating in the survey: <a target="_blank" href="https://www.sp-summit.de/award" class="atom-text-link" rel="noopener nofollow">Survey</a>.<br><br>As a gesture of gratitude, we are raffling vouchers from <a href="https://www.wunschgutschein.de/pages/wertgutschein" rel="noopener nofollow" target="_blank" class="atom-text-link">Wertgutschein.de</a> to all participants. Simply send us a screenshot of your vote to <a href="mailto:{mail}" class="atom-text-link">{mail}</a>. Your support means a lot to us!'
            },
            knowledge_base: {
                title: 'Discover our brand new Knowledge Base!',
                description: '<p>Dive into a world of knowledge about hosting, our Managed Center and various applications! Our new, constantly growing Knowledge Base is your gateway to a wealth of information, tips and tricks.</p><ul class="list-style-none"><li><span class="atom-icon icon-check-line line"></span>Find answers to your questions.</li><li><span class="atom-icon icon-check-line line"></span>Discover new solutions.</li><li><span class="atom-icon icon-check-line line"></span>Deepen your knowledge in various areas.</li></ul><p>The knowledge base can be accessed via <a href="https://knowledge.maxcluster.de" target="_blank" title="maxcluster GmbH - Knowledge Base" rel="nofollow" class="atom-text-link">https://knowledge.maxcluster.de</a> and leads to a large number of helpful articles with just a few keywords. You are welcome to share your feedback with us via <a href="mailto:{mail}" class="atom-text-link">e-mail</a>.</p>'
            },
            dark_mode: {
                title: 'New display options',
                description: '<p>Our brand new dark mode is online! From now on you can experience our Application Center in a cool dark mode, which gives our application a new, stylish look, but most importantly protects your eyes.</p><p>In addition, you can now experience our Application Center over the entire width of your screen.\nYou can switch between modes with just two clicks. Just click on your ID to select and deselect the dark mode and the full width. Give it a try right away.</p><p>And as always: we appreciate any feedback. Feel free to send us your thoughts by <a href="mailto:{mail}" class="atom-text-link">e-mail</a>.</p>'
            },
            new_price: {
                title: 'New prices from 1 September 2024',
                description: '<p>From 1 September 2024, we will adjust our SLA packages and integrate additional services to offer you even more added value.</p><ul><li>Managed Basic: increase in the monthly fee from €40 to €60</li><li>Managed Business: increase in the monthly fee from €120 to €150</li><li>Managed Enterprise: no increase</li></ul><p>A detailed description of services - including our new Managed Dev - and further information can be found <a href="https://maxcluster.de/en/faq-new-pricing" target="_blank" class="atom-text-link">here</a>.</p>'
            },
            knowledge_base_new:{
                title: 'Discover our brand new knowledge base!',
                description: 'Dive into a world of knowledge about hosting, our Managed Center and various applications! Our new, constantly growing Knowledge Base is your gateway to a wealth of information, tips and tricks.',
                features: ['Find answers to your questions.', 'Discover new solutions.', 'Deepen your knowledge in various areas.'],
                additionalText: 'The knowledge base can be accessed via <a href="https://knowledge.maxcluster.de" target="__blank">https://knowledge.maxcluster.de</a> and leads to a large number of helpful articles with just a few keywords. You are welcome to share your feedback with us via <a href="mailto:{mail}">email</a>.'
            }            
        },
        phone: 'Phone',
        fax: 'Fax',
        mobile: 'Mobile',
        application_center_title: 'Application Center',
        login_sub_title: 'Login',
        forgot_password_sub_title: 'Forgot password',
        two_factor_sub_title: 'Two-factor authentication',
        reset_password_sub_title: 'Reset password',
        price_changes: '<strong>Please note:</strong> Starting from September 1, 2024, new prices will apply to our Managed Basic and Managed Business SLAs. You can find the price changes <a href="https://maxcluster.de/en/faq-new-pricing" class="atom-text-link" target="_blank">here</a>.',
    },
};
