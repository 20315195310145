<template>
    <div class="row">
        <div class="col-6">
            <h3>{{ currentCustomer?.customer?.company }}</h3>
        </div>
        <div class="col-6 d-flex justify-content-end relative-1">
            <AtomButton icon-left class="me-2" @click.prevent="downloadContract" :loading="isDownloadingContract" :disabled="isDownloadingContract">
                <template #button-left>
                    <AtomIcon name="download-line" v-if="!isDownloadingContract" />
                </template>
                {{ $t('partner.partner_contract') }}
            </AtomButton>
            <AtomButton :to="{name: 'order-cluster'}" icon-left variant="secondary">
                <template #button-left>
                    <AtomIcon name="add-line" />
                </template>
                {{ $t('orders.order_cluster') }}
            </AtomButton>
        </div>
        <div class="col-12">
            <hr class="mt-2">
        </div>
    </div>
</template>
<script>
    import {partnerStore} from "@/stores/partnerStore";
    import PartnerService from "@/services/Api/Accounting/PartnerService";

    export default {
        name: "PartnerHeaderLine",
        data() {
            return {
                store: null,
                isDownloadingContract: false
            };
        },
        mounted() {
            this.store = partnerStore();
        },
        computed: {
            currentCustomer() {
                return this.store?.currentCustomer || {};
            },
        },
        methods: {
            async downloadContract() {
                let partnershipId = this.currentCustomer.partnerships.find(p => !p.end_date).id;
                let partnerId = this.currentCustomer.id;
                this.isDownloadingContract = true;

                await PartnerService.downloadPartnerContract({partnerId: partnerId, partnershipId: partnershipId}).then(response => {
                    window.downloadPdf(response.data, `partner-${partnerId}-partnership-${partnershipId}.pdf`);
                    this.isDownloadingContract = false;
                });
            },
        }
    };
</script>