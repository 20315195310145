export default {
    de: {
        next: 'Weiter',
        prev: 'Zurück',
        skip: 'Schließen',
        finish: 'Schließen',
        get_advice: 'Beratung anfordern',
        apache: {
            hint1: 'Domains und Weiterleitungen werden beim Anlegen auf Gültigkeit geprüft.',
            hint2: 'Sie können für bereits registrierte Domains mit nur wenigen Klicks SSL-Zertifikate hinterlegen, PHP Einstellungen verändern und sogar pro Domain zwischen verschiedenen PHP-Versionen wechseln.'
        },
        profiler: {
            hint1: 'Die professionellen Profiler Dienste und Werkzeuge können ohne weitere Konfiguration eingerichtet werden. Notwendige Pakete werden installiert und regelmäßig automatisch aktualisiert.'
        },
        varnish: {
            hint1: 'Die default.vcl für Varnish SSL können Sie hier hochladen und auch direkt bearbeiten.'
        },
        ssh: {
            hint1: 'Es können SSH Keys eingefügt werden, um ein sicheres Anmelden ohne Passwort zu ermöglichen.',
            hint2: 'Angriffe gegen den SSH Dienst werden erkannt und die Angreifer gesperrt.',
        },
        supervisor: {
            hint1: 'Mit einem Klick können alle Programme einer Gruppe starten, stoppen oder neustarten.',
            hint2: 'Oder mit einem Klick ein einzelnes Programm starten, stoppen und neustarten.',
            hint3: 'Zu jedem Programm werden die Prozesse und deren Status angezeigt.',
        },
        mysql: {
            hint1: 'Datenbanken und Datenbanknutzer können mit einem Klick angelegt werden.'
        },
        elasticsearch: {
            hint1: 'Mit einem Klick können Sie einen Elasticsearch Server installieren und bearbeiten.',
            hint2: 'Installierte Elasticsearch Server können Sie konfigurieren und deaktivieren. Zusätzlich können Sie die Leistungsdaten einzelner Elasticsearch Server einsehen',
        },
        opensearch: {
            hint1: 'Mit einem Klick können Sie einen OpenSearch Server installieren und bearbeiten.',
            hint2: 'Installierte OpenSearch Server können Sie konfigurieren und deaktivieren. Zusätzlich können Sie die Leistungsdaten einzelner OpenSearch Server einsehen.'
        },
        redis: {
            hint1: 'Redis Server lassen sich mit einem Klick installieren.',
            hint2: 'Installierte Redis Server können Sie konfigurieren und deaktivieren. Zusätzlich können Sie die Leistungsdaten einzelner Redis Server einsehen.'
        },
        shopsecurity: {
            hint1: 'Domain- und Malware-Scans können täglich automatisch durchgeführt werden.',
            hint2: 'Alle Report-Ergebnisse auf einen Blick.',
            hint3: 'Weitere Details und Handlungsempfehlungen können für jeden Report eingesehen werden.',
        },
        nodejs: {
            hint1: 'Mit einem Klick können Sie Node.js auf eine höhere Version upgraden.',
            hint2: 'Die Übersicht zeigt Ihnen alle aktiven Node.js Prozesse und deren Startzeitpunkt.'
        },
        shopperformance: {
            hint1: 'Alle Report-Ergebnisse auf einen Blick.',
            hint2: 'Weitere Details und Handlungsempfehlungen können für jeden Report eingesehen werden.'
        },
        monitoring: {
            hint1: 'Das Monitoring liefert Leistungsdaten von Hardware und Diensten. Zusätzlich kann pro angelegtem Host auf einer Weltkarte die Anzahl der Zugriffe pro Land und IP eingesehen werden.'
        },
        dashboard: {
            hint1: 'Der aktuelle Ressourcenverbrauch wird in Echtzeit ermittelt.'
        },
        backups: {
            hint1: 'Wir halten Ihre Backups 10 Tage kostenfrei für Sie vor, es wird kein zusätzlicher Speicherplatz für die Sicherungen (dezidiert) benötigt.'
        },
        packageupdates: {
            hint1: 'Wir halten Ihr System durch unseren monatlich durchgeführten Update-Tag stets aktuell und sicher.'
        },
        clustercontrol: {
            hint1: 'Unser Werkzeug zur einfachen Verwaltung und Steuerung von Clustern über die Cluster-Konsole'
        }
    },
    en: {
        next: 'Next',
        prev: 'Back',
        skip: 'Close',
        finish: 'Close',
        get_advice: 'Get advice',
        apache: {
            hint1: 'Domains and redirects are checked for validity when they are created.',
            hint2: 'You can deposit SSL certificates for already registered domains with just a few clicks, change PHP settings and even switch between different PHP versions per domain.'
        },
        profiler: {
            hint1: 'Professional profiler services and tools can be set up without further configuration. Necessary packages are installed and regularly updated automatically.'
        },
        varnish: {
            hint1: 'You can upload and directly edit the default.vcl for Varnish SSL here.'
        },
        ssh: {
            hint1: 'You can insert SSH keys to enable secure passwordless login.',
            hint2: 'Attacks against the SSH service are detected, and attackers are blocked.'
        },
        supervisor: {
            hint1: 'With one click, you can start, stop, or restart all programs in a group.',
            hint2: 'Or start, stop, and restart a single program with one click.',
            hint3: 'Process and status information is displayed for each program.'
        },
        mysql: {
            hint1: 'Databases and database users can be created with one click.'
        },
        elasticsearch: {
            hint1: 'With one click, you can install and edit an Elasticsearch server.',
            hint2: 'Installed Elasticsearch servers can be configured and deactivated. You can also view the performance data of individual Elasticsearch servers.'
        },
        opensearch: {
            hint1: 'With one click, you can install and edit an OpenSearch server.',
            hint2: 'Installed OpenSearch servers can be configured and deactivated. You can also view the performance data of individual OpenSearch servers.'
        },
        redis: {
            hint1: 'Redis servers can be installed with one click.',
            hint2: 'Installed Redis servers can be configured and deactivated. You can also view the performance data of individual Redis servers.'
        },
        shopsecurity: {
            hint1: 'Domain and malware scans can be automatically performed daily.',
            hint2: 'All report results at a glance.',
            hint3: 'Additional details and recommendations can be viewed for each report.'
        },
        nodejs: {
            hint1: 'With one click, you can upgrade Node.js to a higher version.',
            hint2: 'The overview shows all active Node.js processes and their start time.'
        },
        shopperformance: {
            hint1: 'All report results at a glance.',
            hint2: 'Additional details and recommendations can be viewed for each report.'
        },
        monitoring: {
            hint1: 'The monitoring provides performance data of hardware and services. In addition, the number of accesses per country and IP can be viewed on a world map for each host created.'
        },
        dashboard: {
            hint1: 'The current resource consumption is determined in real time.'
        },
        backups: {
            hint1: 'We store your backups for you for 10 days free of charge, no additional storage space is required for the backups (dedicated).'
        },
        packageupdates: {
            hint1: 'We keep your system up-to-date and secure with our monthly update day.'
        },
        clustercontrol: {
            hint1: 'Our tool for easy management and control of clusters via the cluster console.'
        }
    }
};
