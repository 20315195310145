import { userStore } from '@development/cluster-center';

export default function isAuthenticated({nextRoute}) {
    const store = userStore();

    if (store.isLoggedIn) {
        return nextRoute({ name: 'dashboard' });
    }

    return nextRoute();
}
