export default {
    de: {
        actions: {
            request_new: 'Jetzt anfordern',
            terminate: 'Kündigen',
            edit_termination: 'Kündigung editieren',
            abort_termination: 'Kündigung abbrechen',
            confirm_termination: 'Kündigung bestätigen',
            show_termination: 'Kündigung anzeigen',

        },
        status: {
            in_termination: 'In Kündigung'
        }
    },
    en: {
        actions: {
            request_new: 'Request now',
            terminate: 'Terminate',
            edit_termination: 'Edit termination',
            abort_termination: 'Abort termination',
            confirm_termination: 'Confirm termination',
            show_termination: 'Show termination',
        },
        status: {
            in_termination: 'In termination'
        }
    }
};
