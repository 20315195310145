<script>
    import logo from '@/assets/images/logo/maxcluster-tag.svg';

    export default {
        name: "BasePage",
        methods: {
            updatePageTitle(title = null) {
                let fallback = this.$route?.meta?.title || this.$route.path;
                document.title = title || fallback;
            },
            configGet(key) {
                return window.Config.get(key);
            },
        },
        computed: {
            logoImage() {
                return logo;
            }
        }
    };
</script>