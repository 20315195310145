import { defineStore } from 'pinia';
import { notifications as defaultNotifications } from '@/config/notifications.js';

export const notificationStore = defineStore('notificationStore', {
    state: () => ({
        notificationList: []
    }),
    getters: {
    },
    actions: {
        addNotification(notification) {
            this.notificationList.unshift(notification);
        },
        updateNotifications() {
            defaultNotifications.forEach(notification => {
                const exists = this.notificationList.some(n => n.id === notification.id);
                if (!exists) {
                    this.addNotification(notification);
                }
            });
        }
    },
    persist: true
});