export default {
    de: {
        title: 'Cluster-Übersicht',
        search: 'Cluster-Nummer, Kunde, Kundennummer, Cluster-Bezeichnung...',
        cluster_number: 'Cluster-Nummer',
        customer: 'Kunde',
        cluster_title: 'Cluster-Bezeichnung',
        application: 'Anwendung',
        architecture: 'Architektur',
        customer_name: 'Kundenname',
        customer_number: 'Kundennummer',
        av_contract: 'AV-Vertrag',
        show_all: 'alle anzeigen',
        manager: 'Manager',
        administrator: 'Administrator',
        cluster_description: 'Cluster-Beschreibung',
        cluster_name: 'Cluster-Bezeichnung',
        no_clusters: 'Es wurden keine Cluster gefunden.',
        no_administrator: 'Kein Administrator vorhanden.',
        documents: 'Dokumente',
        cluster: {
            edit_description: {
                title: 'Beschreibung bearbeiten - {number}',
                description: 'Beschreibung',
                success: 'Die Beschreibung wurde erfolgreich aktualisiert.'
            },
            edit_data: {
                success: 'Die Cluster-Daten wurden erfolgreich aktualisiert.'
            }
        }
    },
    en: {
        title: 'Cluster overview',
        search: 'Cluster number, customer, customer number, cluster description...',
        cluster_number: 'Cluster number',
        customer: 'Customer',
        cluster_title: 'Cluster description',
        application: 'Application',
        architecture: 'Architecture',
        customer_name: 'Customer name',
        customer_number: 'Customer number',
        av_contract: 'AV contract',
        show_all: 'show all',
        manager: 'Manager',
        administrator: 'Administrator',
        cluster_name: 'Cluster name',
        cluster_description: 'Cluster description',
        no_clusters: 'No clusters found.',
        no_administrator: 'No administrator available.',
        documents: 'Documents',
        cluster: {
            edit_description: {
                title: 'Edit description - {number}',
                description: 'Description',
                success: 'The description was successfully updated.'
            },
            edit_data: {
                success: 'The cluster data was successfully updated.'
            }
        }
    }
};
