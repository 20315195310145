import { defineStore } from 'pinia';

export const partnerStore = defineStore('partner', {
    state: () => ({
        currentCustomer: {}
    }),
    getters: {
    },
    actions: {
    },
    persist: true
});