import * as middlewares from '@/middlewares';
import {getRedirect} from './redirects';

let globalMiddlewares = ['isDemo'];

export function middlewarePipeline (context, middleware, index) {
    let nextMiddleware = middleware[index];

    if (!nextMiddleware) {
        return context.next;
    }

    return () => {
        let nextPipeline = middlewarePipeline(
            context, middleware, index + 1
        );

        middlewares[nextMiddleware]({ ...context, next: nextPipeline });
    };
}

export function beforeEach (router) {
    router.beforeEach((to, from, next) => {
        if (!window.Config.resumeBugsnagSession()) {
            window.Config.startBugsnagSession();
        }

        const redirect = getRedirect(to);

        if (redirect) {
            return next(redirect);
        }

        if (!to.meta.middleware) {
            return next();
        }

        let middleware = [...to.meta.middleware, ...globalMiddlewares];

        let context = {
            to,
            from,
            next,
            nextRoute: next
        };

        return middlewares[middleware[0]]({
            ...context,
            next: middlewarePipeline(context, middleware,1)
        });
    });
}

