export default {
    de: {
        gender: 'Geschlecht',
        firstname: 'Vorname',
        surname: 'Nachname',
        company: 'Unternehmen',
        language: 'Sprache',
        email: 'E-Mail',
        password: 'Passwort',
        password_confirm: 'Passwort bestätigen',
        invalid_token: 'Der angegebene Token ist nicht valide.',
        success: 'Sie haben Sich erfolgreich registriert. Sie können sich nun einloggen.'
    },
    en: {
        gender: 'Gender',
        firstname: 'Firstname',
        surname: 'Surname',
        company: 'Company',
        language: 'Language',
        email: 'Email',
        password: 'Password',
        password_confirm: 'Confirm password',
        invalid_token: 'The given token seems to be invalid.',
        success: 'You have successfully registered. You can now log in.'
    }
};
