<template>
    <div class="row" v-bind="$attrs">
        <div class="col-12 relative-1">
            <h4 class="d-inline cursor-pointer hover-primary" @click.prevent="open('form')">{{ $t('profile.pat.title') }} <AtomIcon name="plus-circle-solid" /></h4>
            <hr v-if="tokens.length > 0">
        </div>
        <div class="col-8"></div>
        <div class="col-4">
            <AtomInput
                v-model="search"
                :placeholder="$t('profile.pat.search')"
                :disabled="actionIsRunning"
                prepend
            >
                <template #input-prepend>
                    <AtomButton>
                        <AtomIcon name="search-line" />
                    </AtomButton>
                </template>
            </AtomInput>
        </div>
        <div class="col-12 mt-3">
            <p v-if="tokens.length === 0">{{ $t('profile.pat.no_tokens') }}</p>
            <AutoSortableTable
                v-else
                :content="tokenList"
                :configuration="tableConfiguration"
                pagination
                initial-sorting-key="id"
            >
                <template #column-id="token">
                    <code>{{ token.id }}|****</code>
                </template>
                <template #column-name="token">
                    <span class="d-inline-block text-truncate max-w-400">
                        {{ token?.name }}
                    </span>
                </template>
                <template #column-abilities="token">
                    <template v-if="token.abilities[0] === '*'">
                        {{ $t('profile.pat.none') }}
                    </template>
                    <template v-else>
                        {{ getAbilityText(token.abilities) }}
                    </template>
                </template>
                <template #column-expires_at="token">
                    <template v-if="token.expires_at">
                        {{ $d(token.expires_at, 'short') }}
                    </template>
                    <template v-else>
                        {{ $t('profile.pat.unlimited') }}
                    </template>
                </template>
                <template #column-last_used_at="token">
                    <template v-if="token.last_used_at">
                        {{ $d(token.last_used_at, 'short') }}
                    </template>
                    <template v-else>
                        -
                    </template>
                </template>
                <template #column-menu="token">
                    <DotMenu>
                        <AtomDropdownItem class="danger" @click.prevent="open('delete', token)">{{ $t('helper.actions.delete') }}</AtomDropdownItem>
                    </DotMenu>
                </template>
            </AutoSortableTable>
            <p v-html="$t('profile.pat.description')"></p>
        </div>
    </div>
    <MoleculeModal :close-text="$t('helper.actions.abort')" @close="showDelete = false" :show-modal="showDelete" v-model="showDelete" size="md">
        <template #modal-headline>
            <h3>{{ $t('profile.pat.delete.title') }}</h3>
        </template>
        <template #content>
            <MoleculeLoader size="md" v-if="actionIsRunning" />
            <p v-else>
                {{ $t('profile.pat.delete.description', {name: token?.name}) }}
            </p>
        </template>
        <template #actions>
            <AtomButton @click.prevent="deleteToken" :disabled="actionIsRunning">{{ $t('helper.actions.delete') }}</AtomButton>
        </template>
    </MoleculeModal>
    <CreatePatForm @close="showForm = false" @updated:token="getTokens" :show="showForm" />
</template>
<script>
    import {ResponseService, UserService, userStore} from "@development/cluster-center";
    import CreatePatForm from "@/components/Accounting/Profile/Forms/CreatePatForm";
    import BaseComponent from "@/components/BaseComponent";

    export default {
        extends: BaseComponent,
        name: "PersonalaccesstokenData",
        components: {CreatePatForm},
        data() {
            return {
                user: null,
                tokens: [],
                showDelete: false,
                token: {},
                showForm: false,
                search: null
            };
        },
        computed: {
            tableConfiguration() {
                return [
                    {key: 'id', title: this.$i18n.t('profile.pat.table.token'), sortable: true},
                    {key: 'name', title: this.$i18n.t('profile.pat.table.name'), sortable: true},
                    {key: 'abilities', title: this.$i18n.t('profile.pat.table.restriction'), sortable: true, sortVal: item => {
                        return this.getAbilityText(item.abilities);
                    }},
                    {key: 'expires_at', title: this.$i18n.t('profile.pat.table.expiry_date'), sortable: true},
                    {key: 'last_used_at', title: this.$i18n.t('profile.pat.table.last_uses'), sortable: true},
                    {key: 'menu', title: '', width: 75},
                ];
            },
            tokenList() {
                if (!this.search || this.search === "") {
                    return this.tokens || [];
                }

                return this.tokens.filter(token => {
                    return (
                        token.name.includes(this.search)
                    );
                });
            },
        },
        mounted() {
            this.getTokens();
            this.user = userStore();
        },
        methods: {
            async getTokens() {
                const i18n = window.Config.get('i18n');
                this.actionIsRunning = true;

                return await UserService.showPersonalAccessTokens().then(response => {
                    if (Array.isArray(response)) {
                        if (response[2] !== 401) {
                            ResponseService.danger(i18n.t(response[1], {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }));
                        }

                        return;
                    }

                    this.tokens = response.data;
                    this.actionIsRunning = false;
                });
            },
            async deleteToken() {
                const i18n = window.Config.get('i18n');
                this.actionIsRunning = true;

                return await UserService.deletePersonalAccessTokens(this.token.id).then(response => {
                    if (Array.isArray(response)) {
                        if (response[2] !== 401) {
                            ResponseService.danger(i18n.t(response[1], {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }));
                        }

                        return;
                    }

                    this.getTokens().then(() => {
                        this.actionIsRunning = false;
                        this.showDelete = false;
                        this.token = {};
                    });

                    ResponseService.success(i18n.t('profile.pat.delete.success'));
                });
            },
            getAbilityText(abilities) {
                let abilities_text = [];

                abilities.forEach(ability => {
                    if (ability === '*') {
                        abilities_text.push('-');
                    } else if (ability.match(/(cluster:(\d+))/)) {
                        abilities_text.push('C-' + ability.match(/(cluster:(\d+))/)[2]);
                    }
                });

                return abilities_text.join(', ');
            },
            open(type, token) {
                this[`show${type.ucfirst()}`] = true;
                this.token = token;
            },
        }
    };
</script>
