<template>
    <div class="row center">
        <div class="col-8" :class="{message: !fullWidth}">
            <AtomImage class="mb-4" :size="400" :src="logoImage" alt="maxcluster-logo" draggable="false" v-if="!hideLogo" />
            <div class="d-flex">
                <h2 class="" v-html="$t('errors.403_title')"></h2>
            </div>
            <slot>
                <p class="lead" v-html="$t('errors.403_default_message')"></p>
            </slot>
        </div>
        <div class="col-4" id="alpaka">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="600px" height="1167.398804px" viewBox="0 100 750 1167.398804" enable-background="new 0 0 750 1167.398804" xml:space="preserve">
                <g id="Ebene_1">
                    <g>
                        <g>
                            <g id="ear-right">
                                <path fill-rule="evenodd" clip-rule="evenodd" fill="#DDDDDD" d="M201.833389,97.578377
                                    c-1.808914-10.733131-6.935913-45.808537-5.830078-69.446175c1.25705-26.858753,10.897949-42.131943,32.545975-10.164595
                                    c12.502945,18.462324,15.290878,34.324486,11.115677,54.399437c-4.175217,20.074509-27.314651,40.507561-27.314651,40.507561
                                    L201.833389,97.578377z"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" fill="#AFAFAF" d="M212.538391,15.682084
                                    c20.106705,18.413628,20.326126,67.379997,7.11084,75.618538c-5.555222,3.463036-14.573761-27.735001-15.36853-54.752659
                                    C203.485718,9.530052,212.538391,15.682084,212.538391,15.682084z"></path>
                            </g>
                            <g id="ear-left">
                                <path fill-rule="evenodd" clip-rule="evenodd" fill="#DDDDDD" d="M84.405006,103.925583
                                    c1.605713-11.059311,6.06134-47.146088,4.460556-71.148033C87.046349,5.504606,77.002068-9.382506,55.80798,24.687143
                                    C43.567074,44.363811,41.078396,60.715996,45.704296,80.874969c4.625473,20.159073,19.557064,38.650337,19.557064,38.650337
                                    L84.405006,103.925583z"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" fill="#AFAFAF" d="M71.921181,21.245226
                                    c-19.9165,20.155985-19.134026,70.052094-5.626717,77.524704c5.678192,3.141426,14.1409-29.266472,14.389145-56.843742
                                    C80.932281,14.348813,71.921181,21.245226,71.921181,21.245226z"></path>
                            </g>
                        </g>
                        <path id="tail" fill="#D8D8D9" d="M680.792175,732.326355c-1.113831-6.087524,1.30658-11.846069,2.679993-17.400146
                            c5.410583-21.880371-0.347229-41.184814-13.223572-58.335327c-8.062378-10.73877-19.413269-18.466858-32.827209-21.525085
                            c-11.772644-2.683899-14.302917-11.674622-16.702087-21.224792c2.789856,4.520325,5.492798,4.938354,9.442383,0.80011
                            c8.814209-9.235779,19.285156-16.260376,31.235596-20.846008c7.866272-3.018494,15.870178-3.535034,23.779114,0.143921
                            c10.152832,4.722656,13.789124,12.577515,10.771423,23.793518c-0.667664,2.481323-1.84668,4.855042-3.012024,7.166443
                            c-2.450928,4.860352-0.578674,6.537903,4.375488,7.144226c12.236694,1.497742,23.887512,4.862915,34.464661,11.546143
                            c6.153076,3.887817,10.297974,8.908386,9.053345,16.402405c-1.166748,7.02417-6.377686,10.257202-13.055359,12.008179
                            c-7.650024,2.006042-7.672852,2.382751-2.671875,9.536438c7.704712,11.020996,3.051086,25.236206-8.554382,27.804382
                            c-7.941345,1.757385-15.685791,4.491577-23.896912,4.609192c-4.962524,0.071106-7.484741,1.927307-7.934937,7.062561
                            C684.364868,725.013733,683.683777,729.109131,680.792175,732.326355z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#C8C9CA" d="M225.287827,839.508911
                            c-11.407349,30.183411,11.136612,112.223877,12.621399,141.99231c1.582291,31.72937-67.766495,156.813477-67.766495,156.813477
                            l-28.287643,0.203735l34.854965-130.194824c-3.291046-2.141235-5.467255-5.851318-5.467255-10.070557
                            c0-2.190674,0.586853-4.244446,1.611893-6.01239c-1.024704-1.761902-1.611893-3.809631-1.611893-5.994568
                            c0-3.327698,1.36203-6.336914,3.558075-8.50238c-3.858719-2.637634-6.391754-7.071411-6.391754-12.098145
                            c0-0.166809,0.003708-0.332581,0.00943-0.497681c-6.427765-4.574402-10.620758-12.083008-10.620758-20.573303
                            c0-5.875854,2.008408-11.282898,5.375793-15.571777l-40.032372-71.976257L225.287827,839.508911z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#C8C9CA" d="M573.491577,832.186951
                            c-6.432434,17.020874-2.070923,50.524597,3.223083,81.849121c4.215881,1.496887,7.219421,5.370728,7.219421,9.91449
                            c0,0.340332-0.017822,0.676636-0.050476,1.00824c0.887878-0.258606,1.825806-0.397827,2.796387-0.397827
                            c5.560425,0,10.067505,4.553162,10.067505,10.169434c0,1.802551-0.464417,3.495483-1.278931,4.96344
                            c3.049255,1.973083,4.940247,4.715637,4.940247,7.748413c0,2.346375-1.131287,4.51886-3.055298,6.296204
                            c1.910889,2.178589,3.055298,4.943665,3.055298,7.953552c0,5.16626-3.368408,9.61322-8.207458,11.606445
                            c4.132141,3.139709,6.835388,8.345337,6.835388,14.237366c0,9.589966-7.159912,17.364258-15.992188,17.364258
                            c-1.372131,0-2.703552-0.187317-3.974731-0.540771c-17.362915,48.420349-60.723328,126.633362-60.723328,126.633362
                            l-28.287659,0.203857l35.817139-133.789917c-1.464966-1.596802-2.349731-3.666748-2.349731-5.92804
                            c0-2.477478,1.061035-4.724365,2.783875-6.372192l-0.056824-0.39856c-0.771118,0.302673-1.582947,0.465149-2.422058,0.465149
                            c-4.998413,0-9.050903-5.736633-9.050903-12.813477c0-6.526917,3.446777-11.913818,7.90448-12.711487l-0.091492-0.642029
                            c-2.766418-2.343994-4.497009-5.687561-4.497009-9.399292c0-0.661499,0.05481-1.310913,0.161072-1.94519
                            c-4.317078-2.097168-7.341461-6.960083-7.341461-12.625061c0-0.373291,0.013458-0.742554,0.039337-1.108459
                            c-0.944336,0.257263-1.947845,0.396179-2.988708,0.396179c-5.56076,0-10.067871-3.961304-10.067871-8.847107
                            c0-0.16748,0.006073-0.33429,0.016479-0.49939c-2.765076-3.522766-4.490967-8.510803-4.490967-14.042969
                            c0-2.975586,0.499725-5.794128,1.391266-8.311707l-27.538055-37.461304L573.491577,832.186951z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#F7F7F7" d="M247.998962,215.764099
                            c-0.012787,0.933563-0.027588,1.873871-0.043396,2.81517c7.133972,3.900772,11.972687,11.473953,11.972687,20.178116
                            c0,7.074432-3.197571,13.401276-8.22493,17.616821c6.346359,3.38858,10.665817,10.075592,10.665817,17.772858
                            c0,10.257568-7.669373,18.722931-17.586578,19.975677l-0.12883,2.359467
                            c9.502579,4.438171,16.088028,14.078949,16.088028,25.258636c0,8.290497-3.622314,15.734192-9.36937,20.837921
                            c3.462234,2.555878,5.708054,6.664154,5.708054,11.297699c0,7.750427-6.283127,14.033539-14.033524,14.033539
                            c-0.433502,0-0.862961-0.019501-1.286026-0.058167c9.755112,2.57605,16.946594,11.459808,16.946594,22.024384
                            c0,8.678955-4.853516,16.22287-11.994888,20.068146c5.924286,3.140717,9.960922,9.369019,9.960922,16.541992
                            c0,7.484406-4.394791,13.940735-10.7435,16.933136c8.86525,1.471985,15.624908,9.173981,15.624908,18.456573
                            c0,6.74823-3.572174,12.661072-8.928802,15.952484c9.463867,2.445587,16.454224,11.040436,16.454224,21.268372
                            c0,3.45752-0.799713,6.728394-2.223267,9.638092c0.564972-0.051819,1.13736-0.079712,1.71579-0.079712
                            c10.222198,0,18.508362,8.286102,18.508362,18.508331c0,2.9198-0.676636,5.681824-1.881256,8.137878
                            c0.617767-0.06897,1.245667-0.105286,1.882263-0.105286c8.342957,0,15.254333,6.126099,16.483154,14.125
                            c0.63327-0.059204,1.274933-0.09082,1.923645-0.09082c9.775635,0,17.947418,6.86261,19.962891,16.032532
                            c2.904633-3.694275,7.413727-6.066528,12.477783-6.066528c4.539062,0,8.631531,1.906494,11.523376,4.962097
                            c3.106415-3.423218,7.591675-5.572144,12.578369-5.572144c3.864441,0,7.426544,1.29071,10.280396,3.464905
                            c4.068573-8.542419,12.781158-14.44751,22.87323-14.44751c8.657074,0,16.298187,4.345032,20.864166,10.972168
                            c2.430115-1.03949,5.105042-1.616577,7.915527-1.616577c5.590332,0,10.64798,2.279114,14.296173,5.958252
                            c4.386383-5.723572,11.291321-9.415466,19.05957-9.415466c4.542755,0,8.790253,1.262451,12.411896,3.455811
                            c3.833832-2.93219,8.625793-4.67627,13.825684-4.67627c9.098999,0,16.950958,5.335449,20.600494,13.047852
                            c3.300812-3.346191,7.886932-5.421509,12.959076-5.421509c6.574707,0,12.334534,3.48645,15.533447,8.710571
                            c2.840027-1.47168,6.064514-2.303711,9.484009-2.303711c9.523376,0,17.53949,6.448608,19.92218,15.21698
                            c1.373779-0.242798,2.787292-0.369568,4.230652-0.369568c9.091919,0,17.009155,5.024353,21.129517,12.44751
                            c2.104248-0.686401,4.350403-1.057983,6.684021-1.057983c10.67688,0,19.538452,7.762512,21.255249,17.951416
                            c1.553711-0.431091,3.189819-0.662842,4.880737-0.662842c10.053406,0,18.203369,8.149963,18.203369,18.203369
                            c0,1.686523-0.230347,3.319275-0.659851,4.868958c2.433472,0.581482,4.675964,1.654968,6.61676,3.108459
                            c18.152527,1.007568,36.366638,2.100159,53.784363,32.662964c19.772522,34.695557,6.69812,64.936462-11.294006,59.984436
                            l-0.18396,1.036133c4.437866,3.171326,7.259094,7.924316,7.259094,13.23822c0,7.015198-4.916748,13.052856-11.980408,15.763123
                            c5.495178,3.295715,9.132935,9.032043,9.132935,15.558655c0,8.666809-6.415955,15.939697-15.077759,17.952454
                            c3.784729,3.205627,6.128418,7.646851,6.128418,12.556152c0,7.521362-5.502197,13.946106-13.260681,16.507019
                            c2.205139,3.392639,3.499207,7.5177,3.499207,11.967651c0,9.48407-5.874512,17.494385-13.932007,20.075195
                            c1.85437,2.415283,2.949036,5.387878,2.949036,8.602905c0,7.155518-5.41748,13.110718-12.578674,14.389038
                            c7.93103,2.007385,13.7995,9.191101,13.7995,17.745972c0,4.620789-1.71283,8.841003-4.537415,12.061768
                            c4.202454,2.508484,6.977905,6.826965,6.977905,11.734619c0,4.727051-2.574036,8.907959-6.519836,11.450684
                            c3.405701,1.987244,5.706665,5.769287,5.706665,10.108887c0,3.373108-1.390564,6.408569-3.609192,8.527283
                            c6.448975,0.983704,11.338745,5.715454,11.338745,11.405029c0,4.596863-3.19281,8.56897-7.820007,10.443481
                            c6.225281,0.653809,11.074402,5.836182,11.074402,12.133118c0,6.739807-5.554688,12.203369-12.406494,12.203369
                            c-0.346375,0-0.689758-0.014771-1.029053-0.042358c-7.558716,41.081604-66.997375,148.295593-66.997375,148.295593
                            l-28.287659,0.203735l37.24408-139.119202l-0.001038-0.009094c-1.036499,0.282837-2.118713,0.434143-3.232544,0.434143
                            c-7.489746,0-13.561035-6.780151-13.561035-15.143311c0-5.679138,2.799744-10.626465,6.94165-13.218689
                            c-2.763428-2.233704-4.501404-5.465881-4.501404-9.061951c0-3.021362,1.227112-5.786438,3.258423-7.917542
                            c-4.820251-2.172852-8.113647-6.542786-8.113647-11.580566c0-0.366272,0.017822-0.72876,0.052124-1.086914
                            c-4.931152-1.040527-8.621765-5.270203-8.621765-10.330566c0-2.101868,0.636963-4.059448,1.73468-5.705688
                            c-0.170532,0.006104-0.342041,0.010132-0.514221,0.010132c-7.414124,0-13.423828-5.827454-13.423828-13.016602
                            c0-0.592896,0.041016-1.176331,0.120422-1.748047c-0.639343,0.079346-1.2901,0.120728-1.950562,0.120728
                            c-8.649353,0-15.661255-7.011597-15.661255-15.660889c0-1.062744,0.105957-2.100891,0.307739-3.104065
                            c-4.050415-0.320862-7.591675-2.1073-9.862732-4.718994c-2.028564,6.497986-8.909668,11.28125-17.08783,11.28125
                            c-2.472839,0-4.826599-0.438232-6.963806-1.227539c-2.582123,3.219116-6.780518,5.313904-11.522034,5.313904
                            c-3.719482,0-7.104706-1.289062-9.638397-3.398682c-1.25238,0.328613-2.517883,0.649109-3.795837,0.961853
                            c-1.501923,6.520874-7.128906,11.367004-13.842834,11.367004c-4.01712,0-7.645508-1.735657-10.233673-4.526306
                            c-0.852875,9.131592-10.150604,16.322754-21.495392,16.322754c-7.35257,0-13.843536-3.021667-17.734894-7.633362
                            c-2.129425,3.855652-5.870117,6.412903-10.12973,6.412903c-6.081329,0-11.105011-5.213989-11.891968-11.97467
                            c-2.235046,7.613159-9.540222,13.195435-18.209381,13.195435c-3.642487,0-7.043823-0.986328-9.929962-2.69342
                            c-3.79248,6.217224-11.040131,10.421997-19.358215,10.421997c-12.243744,0-22.169678-9.106018-22.169678-20.338867
                            c0-1.52948,0.185638-3.018616,0.534363-4.451599l-0.558899-0.075012c-1.541962,7.043823-7.047546,12.255188-13.602753,12.255188
                            c-6.086395,0-11.26712-4.49231-13.211609-10.773132c-3.100372,1.924988-6.806061,3.044556-10.788544,3.044556
                            c-7.337097,0-13.73761-3.798828-17.136932-9.435913c-0.469482,21.863281-1.469299,72.161926-1.282654,95.656616
                            c0.242462,30.497131-53.029739,137.352722-53.029739,137.352722l-32.661621,0.545044l16.402451-30.366333l2.985657-104.939575
                            c-3.184418-1.749451-5.378784-5.43396-5.378784-9.696899c0-1.447449,0.253235-2.827942,0.712296-4.089111
                            c-4.72438-1.97876-7.946136-5.786377-8.029205-10.17511c-4.869339-1.129639-8.54512-6.240784-8.54512-12.376953
                            c0-0.864624,0.072998-1.709045,0.212234-2.524902c-8.402832-0.290283-15.140671-8.535706-15.140671-18.665466
                            c0-2.586792,0.439911-5.050537,1.234238-7.289978c-8.879684-0.189026-16.001236-5.668396-16.001236-12.401123
                            c0-2.042725,0.656479-3.970398,1.818069-5.669739c-0.790649,0.116699-1.601807,0.177917-2.428101,0.177917
                            c-8.425034,0-15.254303-6.282776-15.254303-14.033508c0-3.527161,1.414474-6.749268,3.749756-9.214722
                            c-5.513321-2.003967-9.445374-7.230103-9.445374-13.361938c0-0.330566,0.011444-0.658447,0.03363-0.983337
                            c-0.87001,0.111328-1.764572,0.169861-2.677628,0.169861c-8.649368,0-15.661263-5.190491-15.661263-11.593323l0.002045-0.19104
                            c-1.30619,0.257263-2.668213,0.394165-4.069588,0.394165c-9.644455,0-17.464813-6.44458-17.490707-14.401123
                            c-0.864975,0.107605-1.747765,0.164429-2.644676,0.164429c-10.895844,0-19.728806-8.195312-19.728806-18.305237
                            c0-0.5495,0.02755-1.092651,0.078697-1.630005l-0.282494,0.002686c-7.975395,0-14.440796-6.647339-14.440796-14.847412
                            c0-2.164429,0.450977-4.220581,1.260792-6.074951c-7.383503-1.178406-13.056911-8.208435-13.056911-16.706482
                            c0-2.929138,0.674286-5.683838,1.86042-8.085022c-0.336975-0.635254-0.668232-1.267517-0.994106-1.896362
                            c-11.346821-0.336304-20.391991-6.574402-20.391991-14.221863c0-1.990906,0.613386-3.886353,1.720856-5.607178
                            c-4.036293-3.432617-6.602274-8.575378-6.602274-14.325134c0-4.865601,1.837215-9.296387,4.848461-12.624756
                            c-0.259289,0.009094-0.518913,0.014465-0.780556,0.014465c-10.558893,0-19.118416-7.193848-19.118416-16.06781
                            c0-2.75769,0.82663-5.3526,2.283153-7.620239c-4.823912-2.942993-7.978421-7.815369-7.978421-13.328979
                            c0-3.345581,1.161922-6.453979,3.152828-9.039795C2.826952,694.290161,0,688.658081,0,682.329895
                            c0-8.644958,5.27419-15.99115,12.615013-18.656982c-0.268398-0.927856-0.411298-1.900146-0.411298-2.902649
                            c0-4.08136,2.367901-7.670044,5.947467-9.735901c-1.18947-2-1.879927-4.383362-1.879927-6.942322
                            c0-7.076782,5.281647-12.813416,11.796484-12.813416l0.110979,0.001709c0.200771-0.360168,0.413988-0.711975,0.639309-1.054993
                            c-4.519894-3.312256-7.455809-8.658081-7.455809-14.691345c0-5.967346,2.871008-11.264099,7.307837-14.58374
                            c-5.56142-4.152649-9.161867-10.784851-9.161867-18.259155c0-10.772095,7.47834-19.796082,17.526701-22.166321
                            c-5.681147-4.184265-9.368021-10.919373-9.368021-18.515747c0-9.561035,5.838869-17.757385,14.14451-21.221008
                            c-4.111622-3.451416-6.725697-8.627441-6.725697-14.415527c0-9.42923,6.935886-17.237823,15.983749-18.604218
                            c-5.27523-3.472321-8.758644-9.446045-8.758644-16.234314c0-6.844055,3.540585-12.861176,8.889801-16.321045
                            c-2.782562-2.915405-4.491982-6.864258-4.491982-11.212646c0-3.758484,1.276936-7.219025,3.419849-9.971008
                            c-6.03796-3.772614-10.055084-10.477814-10.055084-18.122284c0-10.018433,6.89856-18.424286,16.204334-20.729279
                            c-4.618404-3.058014-7.663952-8.300262-7.663952-14.25415c0-6.140869,3.24094-11.524048,8.104507-14.535645
                            c-5.788734-3.074127-9.731533-9.164215-9.731533-16.176117c0-6.197693,3.080521-11.675385,7.793789-14.986603
                            c-6.023499-2.745911-10.210453-8.818512-10.210453-15.870422c0-6.472107,3.526794-12.1203,8.763012-15.127869
                            c-4.839046-3.595062-7.974384-9.353882-7.974384-15.84552c0-5.033417,1.885948-9.625275,4.988361-13.110718
                            c-6.181896-3.980469-10.276371-10.922729-10.276371-18.821808c0-6.587463,2.84713-12.509064,7.378124-16.603531
                            c-2.463074-3.315948-3.920605-7.423187-3.920605-11.870789c0-5.041824,1.873199-9.644791,4.959438-13.155106l-0.032619-0.395493
                            c-5.790108-2.675613-9.807568-8.533997-9.807568-15.330994c0-4.400162,1.6842-8.406525,4.442211-11.411377
                            c-3.592712-3.094986-5.867119-7.677444-5.867119-12.791916c0-4.318115,1.621983-8.256546,4.288853-11.241898
                            c-3.886639-3.568832-6.322807-8.692719-6.322807-14.385635c0-10.085693,7.647839-18.385284,17.460419-19.416718
                            c-2.293213-3.436661-3.630684-7.565773-3.630684-12.007317c0-11.962921,9.697933-21.660851,21.661194-21.660851
                            c2.081367,0,4.094467,0.294258,6.00029,0.842773c1.278961-1.289719,2.572037-2.540092,3.877563-3.750778
                            c-1.938446-3.000816-3.064049-6.576042-3.064049-10.414253c0-10.430733,8.30867-18.920334,18.669121-19.212242
                            c3.375793-5.837189,9.686134-9.764523,16.913956-9.764523c5.525101,0,10.513802,2.294926,14.065819,5.983482
                            c2.052422-4.262287,6.412605-7.203918,11.459816-7.203918c5.056946,0,9.423172,2.9534,11.470581,7.228466
                            c3.38353-3.692253,8.245102-6.00803,13.648132-6.00803c7.023972,0,13.133255,3.912537,16.268906,9.676746
                            c2.271072-1.116184,4.825928-1.744732,7.528137-1.744732c9.435608,0,17.084793,7.649181,17.084793,17.084797
                            c0,1.393974-0.168152,2.748596-0.483292,4.045372c6.431122,4.92514,12.377274,10.799995,17.682098,17.588242
                            c0.988052-0.162094,2.001328-0.247513,3.035126-0.247513c10.279709,0,18.613617,8.333557,18.613617,18.613289
                            c0,4.732773-1.767929,9.052231-4.676941,12.336548l0.139557,0.371964c7.772614,1.318634,13.691162,8.082672,13.691162,16.230591
                            c0,4.928497-2.165421,9.350189-5.59671,12.367157c7.077805,1.032104,12.513794,7.125214,12.513794,14.489212
                            c0,5.822067-3.397675,10.849106-8.3181,13.209595c4.290878,2.793671,7.128922,7.63237,7.128922,13.133926
                            C258.331818,207.812241,254.025146,213.584183,247.998962,215.764099z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#F7F7F7" d="M649.966553,843.576477
                            c-11.407349,30.183411,11.136292,112.224243,12.621094,141.99231c1.582642,31.72937-67.766479,156.813782-67.766479,156.813782
                            l-28.287659,0.203735l37.24408-139.119202l-7.993896-56.135803l-52.031616-70.779785L649.966553,843.576477z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#F7F7F7" d="M278.212982,895.263672l-0.008423,0.371948
                            c9.514313,1.777039,16.711853,10.047729,16.711853,19.984436c0,8.114929-4.800385,15.118774-11.742981,18.381897
                            c1.516052,2.601318,2.387054,5.633728,2.387054,8.872314c0,5.271179-2.304993,9.996582-5.950531,13.203186
                            c2.465118,2.700867,3.966034,6.273376,3.966034,10.191284c0,4.178223-1.706757,7.963989-4.469788,10.718262
                            c5.035431,2.414001,8.487915,7.362,8.487915,13.07373c0,6.619141-4.635925,12.212158-10.999084,14.025146
                            c-2.507477,33.563538-52.926834,134.700989-52.926834,134.700989l-32.661621,0.545044l16.402451-30.366333l3.429932-120.549316
                            l-30.837845-48.40625c0,0-10.680954-14.643921-22.311935-35.796631c-4.005356-7.284302-10.173462-14.182556-17.877808-21.292969
                            L278.212982,895.263672z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#C8C9CA" d="M113.113052,80.615875l-0.447639-0.622154
                            c9.646843-6.951363,14.404503-5.998955,16.782829-2.698486c2.107605,2.92347,2.25322,7.68718,2.333923,10.333878
                            c0.01413,0.469475-0.008087,0.846138-0.025558,1.110466c0.879105-0.708923,4.797348-1.993927,8.411926-2.0205
                            c1.456177-0.010757,2.87706,0.169495,4.047714,0.65377c1.217087,0.50412,2.166122,1.33075,2.61879,2.597595
                            c0.798035,2.232704,0.001984,5.831474-3.727905,11.460152l-0.639313-0.423401
                            c3.573547-5.393608,4.365845-8.758644,3.643829-10.778137c-0.371277-1.039841-1.164948-1.724213-2.186996-2.147621
                            c-1.069077-0.442238-2.389389-0.607361-3.753128-0.596931c-3.705338,0.027908-7.654877,1.254738-7.885239,1.759865
                            c-0.264999,0.582138-0.537415,0.771812-0.844116,0.631577c-0.362869-0.165123-0.388428-0.98806-0.425751-2.225983
                            c-0.078033-2.561951-0.218933-7.172981-2.190002-9.907448C126.687538,74.774315,122.273552,74.014275,113.113052,80.615875
                            L113.113052,80.615875z M131.742157,89.138771c0.003021,0.021187,0.008377,0.034637,0.016479,0.039345
                            C131.826569,89.208717,131.795624,89.184174,131.742157,89.138771z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#C8C9CA" d="M180.821014,111.555298
                            c6.46405-8.363152,11.253662-8.455971,14.657364-5.825752c3.578598,2.766411,5.722519,8.495987,6.65744,10.994705
                            c0.254578,0.680679,0.425751,1.137039,0.543793,1.293419l0.255249,0.337646l0.310745-0.286865
                            c0.023544-0.021523,8.290512-7.777649,14.767014-2.785919c2.280792,1.758179,2.083389,6.959427,1.94313,10.663788
                            c-0.031265,0.817551-0.059509,1.566154-0.059509,2.154678h0.768448c0-0.641991,0.027237-1.3526,0.056839-2.127777
                            c0.146622-3.86747,0.352783-9.298065-2.240448-11.297043c-6.126404-4.722351-13.586243,0.977631-15.362259,2.482574
                            l-0.264999-0.701859c-0.958466-2.560936-3.155853-8.433777-6.907639-11.333702
                            c-3.727905-2.881096-8.902237-2.872017-15.731857,5.96431L180.821014,111.555298z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#3E3A39" d="M568.680115,1134.567627
                            c-6.595886,6.172485-23.796387,19.416992-23.592896,23.687988c0.20343,4.271118,12.813416,3.661011,12.813416,3.661011
                            s3.864075,2.847168,10.576355,4.474487c6.711914,1.627441,20.542297,2.033691,23.796692-4.678223
                            c3.254395-6.71167,3.310547-20.714844,3.310547-20.714844C583.890686,1146.556763,571.576355,1139.042725,568.680115,1134.567627z
                            "></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#3E3A39" d="M492.114624,1123.5177
                            c-6.595886,6.172485-22.078522,17.856567-21.875397,22.127686c0.203461,4.270996,12.813416,3.661011,12.813416,3.661011
                            s3.864441,2.84668,10.576355,4.474121c6.71225,1.627686,20.542633,2.033936,23.797028-4.677979
                            c3.254395-6.711548,3.310242-20.714844,3.310242-20.714844C504.203003,1131.094604,492.114624,1123.5177,492.114624,1123.5177z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#3E3A39" d="M199.012573,1125.875488
                            c-6.595886,6.172485-23.796371,19.417114-23.592896,23.687744c0.203461,4.271362,12.813431,3.661377,12.813431,3.661377
                            s3.864105,2.846802,10.57634,4.474121c6.711914,1.627319,20.542328,2.033691,23.796707-4.677979
                            c3.254395-6.711548,3.310562-20.715088,3.310562-20.715088C214.222839,1137.86438,201.908813,1130.350342,199.012573,1125.875488z
                            "></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#3E3A39" d="M148.278107,1120.52063
                            c-6.596222,6.172119-22.07888,17.856567-21.87542,22.127319c0.203133,4.270996,12.813438,3.661255,12.813438,3.661255
                            s3.86441,2.846802,10.57634,4.474121c6.711914,1.627441,20.542328,2.033691,23.796722-4.677856
                            c3.254379-6.711914,3.310547-20.71521,3.310547-20.71521C160.366135,1128.097412,148.278107,1120.52063,148.278107,1120.52063z"></path>
                        <path opacity="0.5" fill="#B5B5B5" d="M97.758186,801.716736c-10.473976-8.294373-10.423256-18.63739-4.172516-30.924316
                            c2.697868-5.302979,2.827606-8.021973-0.900734-12.466064c-4.709488-5.613464-9.148048-11.923645-5.157249-20.11969
                            c0.787178-1.616638,0.868889-4.231628,2.991959-4.066589c2.613274,0.203125,1.442245,2.856079,1.418312,4.379944
                            c-0.088364,5.617126-0.143959,10.806641,4.667152,15.264099c3.512199,3.253723,9.290611,4.551941,10.674973,9.275208
                            c1.712616,5.842896-5.147133,8.355713-6.995468,13.177612c-3.676842,9.592285-0.206207,18.355652,8.918869,20.085693
                            c4.581635,0.868774,8.532509-1.71106,12.708275-3.077026c5.705894-1.866638,10.681763-9.990112,14.669724-8.199219
                            c5.013107,2.251465,3.138016,11.093506,4.967377,16.888794c3.998962,12.667908,15.761368,18.737,27.62912,15.273926
                            c2.691254-0.7854,5.783829-6.513855,8.129837-1.934143c1.740646,3.397949-4.337631,3.841736-6.401703,6.210266
                            c-1.842987,2.114502-4.654266,1.934753-7.248917,2.17334c-13.373474,1.229492-23.20726-4.513184-28.860916-16.228699
                            c-2.840134-5.885437-5.60376-7.275574-10.963554-4.007507c-3.983192,2.428711-8.383118,2.765259-12.135841,2.549377
                            C102.580009,805.945679,102.779785,805.69342,97.758186,801.716736z"></path>
                        <g>
                            <path fill="#B1B1B1" d="M168.722244,204.889145c-2.338242-5.315796-2.731857-10.139679-3.159332-17.038712
                                c-0.18959-3.059814-11.703018-13.072189-14.967255-9.898849c-5.863937,5.700638-13.538818,6.956894-20.816101,8.128494
                                c-5.293793,0.85228-8.659134,1.787582-9.23114,7.568466c-0.135017,1.364502-1.01722,2.733246-2.401634,3.501831
                                c1.826828-7.420013,1.637169-14.989639,1.87574-22.552002c0.225983-7.164398,0.473007-6.98201-6.791321-7.406448
                                c-9.00415-0.526108-28.32798-4.708328-29.141083-5.028122c-5.917221-2.32724-5.40184-3.629929,0.296745-9.506958
                                c0.778534-0.802917,1.639824-1.528671,2.503731-2.238922c2.066193-1.698685,4.061234-4.320938,6.947296-2.973495
                                c0.058662,0.02739,0.099716,0.09436,0.079353,0.155823c-0.333427,1.006363-2.765495,3.116684-4.039635,4.892303
                                c-2.536331,3.534775-2.150642,4.586731,1.946465,6.284439c3.063927,1.269547,35.289177,4.663818,49.169075,2.15361
                                c7.968842-1.506104,10.86058,4.11673,15.867401-2.548569c1.259964-2.342087,1.847153-6.888,5.238312-5.841217
                                c3.013611,0.930237,1.256317,5.14003,1.428284,7.888916c0.21875,3.495911-0.093964,7.022293,0.047577,10.526459
                                c0.206467,5.113068,0.787445,5.629242,5.760422,3.37764c6.645126-3.008636,12.731827-6.992783,18.060303-12.08847
                                c1.00264-0.958817,2.007736-3.770676,3.819473-1.882187c1.260284,1.313644-0.365265,3.212723-1.612427,4.488281
                                c-3.875427,3.96376-7.382614,8.467804-12.476059,10.900986c-7.111038,3.397079-8.422058,8.857224-8.587234,16.336502
                                C168.292389,203.264832,169.101974,193.790802,168.722244,204.889145z"></path>
                            <path fill="#202021" d="M200.505386,184.329437c-0.065475,5.239075-3.77684,9.276138-8.371124,9.105759
                                c-4.340714-0.160995-8.256851-4.829865-8.044098-9.590332c0.199875-4.472565,3.967499-8.496582,8.11116-8.663254
                                C196.716263,175.000031,200.568924,179.244125,200.505386,184.329437z"></path>
                            <path id="mouth-around" fill="#8D8C8C" d="M167.811066,189.987915c-0.143158-4.305679-2.774872-7.132858-6.148972-10.294785
                                c-6.197815-5.80928-11.767715-6.454773-18.507751-1.636673c-2.35997,1.686829-6.613098,4.170624-9.420135,4.343353
                                c-10.369568,0.638214-12.293602,5.077805-16.39901,14.750534c-1.959641,4.55043-4.11216,9.029297-5.826637,13.670166
                                c-1.90564,5.158844-4.475632,10.043396-5.025551,15.744232c-2.010658,20.838593,12.803268,36.285995,27.305153,42.805313
                                c3.167496,1.423859,6.196976,1.238892,9.349884,0.181946c9.010605-3.020874,16.039261-8.380005,20.67543-16.588837
                                c0.47171-0.83519,0.99054-1.649734,1.618881-2.374451c3.477478-4.010834,3.669312-7.648392,4.535507-14.820251
                                C171.118362,231.022705,170.573944,206.852341,167.811066,189.987915z"></path>
                            <path fill="#282829" d="M137.700073,193.531998c7.337936-0.101898,10.910538,2.06369,11.158478,6.763962
                                c0.248535,4.710785-7.445404,15.997055-10.726456,15.734711c-3.189621-0.255035-10.178566-10.756546-10.194534-15.318161
                                C127.920685,195.872482,130.975769,193.625366,137.700073,193.531998z"></path>
                            <path id="mouth" fill="#C4C3C4" d="M131.143448,241.861893c-2.169434-0.455048-4.310776-0.904205-6.449722-1.352844
                                c-0.286865-0.060165-0.434486-0.474777-0.17926-0.618927c5.068069-2.86203,28.416641-5.977325,27.87825-6.123169
                                c0.56221,1.272247-0.198929,3.0896-1.04863,3.709274c-3.829117,2.792755-8.350433,3.419968-12.742722,4.362885
                                C136.144058,242.366623,133.60321,242.377838,131.143448,241.861893z"></path>
                            <path fill="#343336" d="M97.432541,193.622787c-3.495102,0.065674-7.743553-3.281433-8.249893-6.62384
                                c-0.122093-0.806366-0.03257-1.807953,0.353386-2.500336c1.82383-3.272049,13.06971-3.466858,15.208824-0.271118
                                C107.049026,188.143631,101.029312,193.555222,97.432541,193.622787z"></path>
                        </g>
                        <path opacity="0.5" fill="#B4B4B4" d="M244.521301,643.856873c3.556824-6.133667,3.499207-12.574524,1.519196-19.283997
                            c-3.359665-11.384705-3.043793-11.685791,8.715805-10.685608c2.191147,0.18634,4.352127,0.756714,6.520004,1.184998
                            c12.604279,2.489746,15.599213,0.359009,17.427917-12.334412c0.135162-0.938232,0.640381-1.823181,0.973511-2.732971
                            c0.682495,0.826538,1.610535,1.549377,2.009613,2.495361c4.036682,9.570129-1.283691,17.250183-12.400391,17.996887
                            c-14.878159,0.999451-16.077499,1.109497-14.742371,15.66571c1.648071,17.968628-18.972977,30.89563-34.192856,25.459106
                            c-6.29808-2.249756-12.779312-4.166138-17.69487-8.926575c-4.132904-4.002502-7.61702-3.697876-11.316223,0.410706
                            c-2.77977,3.087524-5.944595,5.130188-10.492905,5.391602c-12.43924,0.715271-21.080658-4.561096-25.389648-16.850586
                            c-1.949783-5.561035-3.696503-9.691772-10.724091-5.92572c-2.510757,1.34552-5.666275,1.623413-8.576797,2.062134
                            c-8.495636,1.280579-17.374298-5.775085-17.845482-14.099731c-0.060745-1.074158,0.209534-3.098145,0.425598-3.111755
                            c2.015976-0.127502,1.28717,1.844177,1.894203,2.863159c4.370201,7.336609,9.866882,11.205872,19.093536,8.327087
                            c4.283661-1.336609,8.23201-3.193604,12.298798-4.885742c6.874588-2.860413,6.942139-2.883362,8.733414,4.10199
                            c0.733719,2.86145,1.199265,5.808838,2.145645,8.595215c2.858688,8.416687,12.62706,12.710999,19.555878,8.449341
                            c2.471924-1.520508,4.405441-3.939148,6.519791-6.013916c6.786011-6.65918,6.781662-6.681213,14.282196-1.225159
                            c1.495956,1.088196,3.06218,2.141357,4.337982,3.460266c5.521637,5.708435,12.405334,7.68988,21.830017,7.654602
                            C237.098434,652.00354,240.742142,650.373901,244.521301,643.856873z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#C8C9CA" d="M76.454826,118.403069
                            c-4.419678-2.26297-7.442017-2.290886-9.30143-1.163261c-0.891541,0.540436-1.513359,1.339821-1.892036,2.2855
                            c-0.370605,0.925507-0.507812,1.990913-0.436852,3.085907c0.17218,2.674606,1.592049,5.561089,3.860741,7.035103
                            c-2.557915,0.775848-4.390419,2.049072-5.610886,3.549988c-1.164585,1.432312-1.772312,3.071457-1.9216,4.685028
                            c-0.149654,1.614243,0.159069,3.205292,0.824612,4.539734c0.699173,1.400696,1.794842,2.526978,3.169979,3.116852
                            l0.300652-0.705902c-1.200256-0.514206-2.161774-1.509323-2.782558-2.752975
                            c-0.605343-1.213364-0.886154-2.660477-0.75029-4.128433c0.136204-1.469971,0.69043-2.963821,1.753479-4.270691
                            c1.264828-1.555405,3.254726-2.851501,6.12167-3.540253l1.092003-0.26265l-1.024376-0.459732
                            c-2.498726-1.121902-4.090775-4.105225-4.268036-6.854492c-0.063194-0.985367,0.056526-1.936424,0.383385-2.752296
                            c0.31884-0.7967,0.837761-1.467621,1.576614-1.915909c1.648521-0.999489,4.412918-0.931221,8.556519,1.190506
                            L76.454826,118.403069z"></path>
                    </g>
                </g>
            </svg>
        </div>
    </div>
</template>
<script>
    import BasePage from "@/pages/BasePage";

    export default {
        extends: BasePage,
        name: 'NotFoundPage',
        props: {
            hideLogo: Boolean,
            fullWidth: Boolean
        },
        mounted() {
            let pageWrapper = document.querySelector('.outerWrapper');
            pageWrapper.classList.add('errorPage');
        },
        unmounted() {
            let pageWrapper = document.querySelector('.outerWrapper');
            pageWrapper.classList.remove('errorPage');
        }
    };
</script>
<style lang="scss">
    .errorPage {
        overflow: hidden;
        height: 100vh;

        .lead {
            font-weight: 500;
            font-size: 1.15rem;
            line-height: 1.725rem;
        }

        .sublead {
            color: $lama-gray;
        }

        #footer {
            margin-top: 0;
        }

        #content {
            max-height: 87vh;
        }

        .container .row.center {
            height: 100vh;
            display: flex;
            align-items: center;
        }

        #alpaka {
            position: relative;
            top: -100px;
            height: 100%;

            svg {
                position: absolute;
                overflow: visible;
                transform: translate(-115px, 100px);
                transform-box: fill-box;
            }
        }

        #alpaka svg #ear-left {
            animation-duration: .5s;
            transform-origin: bottom right;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-direction: alternate;
            transform-box: fill-box;
        }
        #alpaka svg #ear-right {
            animation: leftEar;
            animation-duration: .5s;
            transform-origin: bottom left;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-direction: alternate;
            transform-box: fill-box;
        }
        #alpaka svg #mouth-around {
            transform-box: fill-box;
            animation: mouth-around;
            animation-duration: 3s;
            animation-iteration-count: infinite;
            animation-timing-function: ease;
            animation-direction: alternate;
            transform-origin: top;
        }
        #alpaka svg #mouth {
            transform-box: fill-box;
            animation: mouth;
            animation-duration: 3s;
            animation-iteration-count: infinite;
            animation-timing-function: ease;
            transform-origin: center center;
        }
        #alpaka svg #tail {
            transform-box: fill-box;
            animation: tail;
            animation-duration: .75s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in;
            animation-direction: alternate;
        }

        @keyframes tail {
            0% {
                transform: rotate(-5.5deg);
            }
            100% {
                transform: rotate(5.5deg);
            }
        }
        @keyframes leftEar {
            0% {
                transform: rotate(-7.5deg);
            }
            100% {
                transform: rotate(7.5deg);
            }
        }
        @keyframes rightEar {
            0% {
                transform: rotate(-12.5deg);
            }
            100% {
                transform: rotate(12.5deg);
            }
        }
        @keyframes mouth {
            0% {
                transform: translate(0, 0);
            }
            25% {
                transform: translate(0, 0) scale(1.25);
            }
            50% {
                transform: translate(0, 0);
            }
            75% {
                transform: translate(0, 0) scale(.85);
            }
            100% {
                transform: translate(0, 0);
            }
        }

        .message {
            margin-top: -12rem;
        }
    }
</style>